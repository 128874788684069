import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { HiArrowLongRight } from "react-icons/hi2";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { Link } from "react-router-dom";

import fitness from "../../assets/categories/fitness.png";
import vitaminsnutrition from "../../assets/categories/vitamins-nutrition.png";
import ayurved from "../../assets/categories/ayurved.webp";
import nutritiondrink from "../../assets/categories/nutrition-drink.png";
import skinhairoral from "../../assets/categories/skin-hair-oral.png";
import stomachcare from "../../assets/categories/stomach-care.png";
import sexualwellness from "../../assets/categories/sexual-wellness.png";
import feminine from "../../assets/categories/feminine-care.png";

const Products = () => {
  const categories = [
    {
      img: fitness,
      title: "Fitness Supplements",
    },
    {
      img: vitaminsnutrition,
      title: "Vitamins & Nutrition",
    },
    {
      img: ayurved,
      title: "Ayurvedic Supplements",
    },
    {
      img: nutritiondrink,
      title: "Nutritional Drinks",
    },
  ];

  const categories2 = [
    {
      img: skinhairoral,
      title: "Skin, hair & oral care",
    },
    {
      img: stomachcare,
      title: "Stomach care",
    },
    {
      img: sexualwellness,
      title: "Sexual wellness",
    },
    {
      img: feminine,
      title: "Feminine Care",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  return (
    <div className="bg-formisBlack15 text-formisPeach px-5 lg:px-9 xl:px-14 py-20">
      <div className="mb-14 lg:mb-20 lg:flex lg:gap-20">
        <div className="lg:w-[35%]">
          <h1 className="title-md font-bold mb-4 md:mb-6 lg:mb-8 xl:mb-12">
            Healthcare Just The Way You Need It.
          </h1>
          <Link
            to="/products"
            className="w-fit flex items-center gap-2 border-2 rounded-full border-formisPeach py-2 md2:py-4 px-4 md2:px-6 text-[12px] md2:text-[16px] mb-5
                      hover:bg-formisPeach hover:text-formisBlack15 transition-colors duration-300 group"
          >
            View Catalogue{" "}
            <HiArrowLongRight className="text-2xl group-hover:rotate-90 transition-transform duration-300" />
          </Link>
        </div>
        <div className="font-[Hind] lg:w-[60%] xl:text-[18px] font-medium">
          <p className="mb-5">
            Because let’s be real—healthcare shouldn’t feel like running a
            marathon through red tape. FORMIS forEssentials puts you in control
            by cutting out the waiting, the confusion, and the stress. It's
            important because life doesn’t pause when you’re unwell or in need,
            and neither should your access to care. Whether it’s an urgent
            medicine or just staying ahead with your health essentials, we
            make sure you’re covered in minutes, not hours.
          </p>
          <p>
            It’s not just about convenience; it’s about peace of mind. No more
            “ugh, do I really have to go out for this?” moments. FORMIS brings
            healthcare to you, right where you are, making it simpler, faster,
            and actually designed for real life.
          </p>
        </div>
      </div>

      <div className="relative group">
        <Swiper
          className="mb-8 lg:mb-16"
          modules={[Navigation, Mousewheel]}
          mousewheel={{
            forceToAxis: true,
          }}
          navigation={{
            nextEl: ".product-next",
            prevEl: ".product-prev",
          }}
          spaceBetween={20}
          slidesPerView={1.25}
          breakpoints={{
            200: {
              slidesPerView: 1.25,
            },
            600: {
              slidesPerView: 2.75,
            },
            1024: {
              slidesPerView: 3.15,
            },
            1280: {
              slidesPerView: 4.15,
            },
          }}
          onSlideChange={handleSlideChange}
        >
          {categories2.map((category, index) => (
            <SwiperSlide key={index}>
              <CategoryCard category={category} />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          modules={[Navigation, Mousewheel]}
          mousewheel={{
            forceToAxis: true,
          }}
          navigation={{
            nextEl: ".product-next",
            prevEl: ".product-prev",
          }}
          spaceBetween={20}
          slidesPerView={1.25}
          breakpoints={{
            200: {
              slidesPerView: 1.25,
            },
            600: {
              slidesPerView: 2.75,
            },
            1024: {
              slidesPerView: 3.15,
            },
            1280: {
              slidesPerView: 4.15,
            },
          }}
          onSlideChange={handleSlideChange}
        >
          {categories.map((category, index) => (
            <SwiperSlide key={index}>
              <CategoryCard category={category} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="absolute top-1/2 left-0 z-10 w-full hidden lg:flex justify-between px-10 transition-opacity opacity-0 group-hover:opacity-100 duration-500">
          <button
            disabled={activeIndex === 0}
            className={`${
              activeIndex === 0 ? "opacity-0" : "cursor-pointer"
            } bg-formisGrey/60 rounded-full p-4 text-2xl text-formisBlack15 product-prev`}
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
          <button
            className={`${
              activeIndex === categories.length - 1 ? "opacity-0" : ""
            } bg-formisGrey/60 rounded-full p-4 text-2xl text-formisBlack15 cursor-pointer product-next`}
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Products;


const CategoryCard = ({category}) => {
  return (
    <div className="max-w-[350px] text-formisBlack15 cursor-pointer bg-formisBlack relative bg-[35%] rounded-[20px]">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
        className="relative z-10 h-full flex flex-col items-end text-formisBlack bg-formisWhite rounded-[20px]"
      >
        <img
          src={category.img}
          className="rounded-t-[20px]"
          alt={category.title}
        />
        <div className="py-4 md:py-5 2xl:py-6 flex justify-between w-full items-center px-5 2xl:px-5 bg-formisPeach rounded-b-[20px]">
          <div>
            <h2 className="text-lg 2xl:text-2xl font-bold mb-1 md:max-w-[90%] lg:max-w-full">
              {category.title}
            </h2>
            <p className="text-[12px]">Check out all our products</p>
          </div>
          <div>
            <HiArrowLongRight className="text-xl lg:text-2xl" />
          </div>
        </div>
      </a>
    </div>
  );
};
