import img1 from "../../assets/blog/blog1.webp";
import img2 from "../../assets/blog/MIS-India.webp";
import img3 from "../../assets/blog/data-driven-stratergies.webp";
import img4 from "../../assets/blog/chai-cricket.webp";
import img5 from "../../assets/blog/fragmentation-unity-patient-care.webp";
import img6 from "../../assets/blog/healing-broken-system.webp";
import img7 from "../../assets/blog/health-score.webp";
import img8 from "../../assets/blog/continuous-care-guide.webp";
import img9 from "../../assets/blog/disconnected-experiences-healthcare.webp";
import img10 from "../../assets/blog/healthcare-is-personal.webp";

export const featuredMain = [
  // Blog 1
  {
    id: "cheers-to-change-dry-january",
    img: img1,
    title: "Cheers to Change — A FORMIS’ TAKE on #Dry-January.",
    content: [
      {
        type: "paragraph",
        text: "Welcome to January, the month of new beginnings and, for some, a month of no ‘cheers’! In India, we’re not strangers to abstaining for religious or health reasons, but Dry January adds a global twist to this concept. For the uninitiated, Dry January involves giving up alcohol for the first month of the year, a practice that’s as challenging as convincing your parents that starting a start-up is a good idea.",
      },
      {
        type: "heading",
        text: "The Origins of Dry January",
      },
      {
        type: "paragraph",
        text: "Dry January began in the UK in 2014, courtesy of Alcohol Change UK. It’s like a New Year’s resolution but focused solely on ditching alcohol. Initially, it was more of a British affair, but like their famous Beatles, this movement too had a global appeal. And before you knew it, Dry January was a thing in India, giving our beloved Old Monk and Kingfisher some time off.",
      },
      {
        type: "heading",
        text: "The Indian Twist to Dry January",
      },
      {
        type: "paragraph",
        text: "In India, Dry January has a different flavor, quite literally. We’re the land of spices and diversity, so why should our approach to Dry January be any different? Let’s face it — in a country where a family gathering is incomplete without Uncle Sharma’s homemade whiskey and where office parties mean endless rounds of ‘cheers’, taking on Dry January is like going to a wedding and saying no to paneer.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*bSwRF0r96VVsC0MPrqaVSQ.png",
        alt: "img",
      },
      {
        type: "heading",
        text: "Evidence-Based Benefits — A Doctor’s Perspective",
      },
      {
        type: "paragraph",
        text: "Let’s get a bit serious and talk science. What do doctors say about Dry January? Well, they’re not prescribing Kingfisher instead of Crocin, that’s for sure. Doctors worldwide, including our very own Indian medical maestros, have observed significant health benefits. From weight loss to improved liver function — the perks are as real as the traffic jams in Bengaluru.",
      },
      {
        type: "paragraph",
        text: "A Mayo Clinic expert warns about the risks of rebound binging post-Dry January and suggests making sustainable changes rather than abrupt ones. Moreover, according to Cleveland Clinic, benefits like clearer skin, a healthier glow, and possible weight loss are visible signs of improvement. But it’s not all roses; they also caution about withdrawal symptoms, especially for heavy drinkers. It’s like playing a game of carrom — precision and caution are key.",
      },
      {
        type: "quote",
        text: "“Dr. Sanjay Gupta (a popular name in medicine, and no, not the CNN guy), an Indian cardiologist, points out the benefits of abstaining from alcohol, even temporarily. He notes improvements in liver function, cardiovascular health, and even mental clarity. However, he also cautions about the potential for withdrawal symptoms in regular drinkers and emphasizes the need for medical guidance for those with a heavy drinking habit.”",
      },
      {
        type: "heading",
        text: "Real Stats, Real Impact",
      },
      {
        type: "paragraph",
        text: "Numbers don’t lie, especially if they’re not from a politician. Dry January might seem like a social media trend, but the stats paint a different picture. For instance, a survey conducted by the University of Sussex found that 88% of participants saved money, 71% slept better, and 58% lost weight. The best part? 82% of participants felt a sense of achievement. It’s like hitting a six in a cricket match, but for your health. These aren’t just numbers; they’re victories, small and big, against the bottle. In India, where work-life balance is as elusive as a peaceful auto ride in Delhi, these stats speak volumes about the potential benefits of a booze-free month.",
      },
      {
        type: "heading",
        text: "Participants’ Experiences — The Good, The Bad, The Ugly",
      },
      {
        type: "quote",
        text: "“Rahul, a 30-year-old IT professional from Bengaluru, shared his experience, ‘The first week was tougher than explaining a bug to a client. But by the end, I was sleeping better and saved enough to buy those headphones I wanted.’ Meanwhile, Priya, a teacher from Mumbai, admitted, ‘I missed my weekend wine, but my skin never looked better.’ Their experiences underline a common thread — initial struggle followed by noticeable benefits.”",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*BiXGJAZPb5M06yL9vYx8BQ.png",
        alt: "img2",
      },
      {
        type: "heading",
        text: "Alcohol and Its Many ‘Gifts’ to Your Body",
      },
      {
        type: "paragraph",
        text: "Alcohol, while a staple in many social settings, is not without its consequences. It affects almost every organ in the body. Dr. Anjali Malik, a gastroenterologist from New Delhi, explains, ‘Regular heavy drinking can lead to chronic diseases like liver cirrhosis, pancreatitis, and even certain types of cancer.’ She also notes the often-overlooked impact of alcohol on mental health, including depression and anxiety.",
      },
      {
        type: "subheading",
        text: "Brain: “The Mind Bender”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "Alcohol loves your brain like a clingy friend — initially fun but eventually exhausting. It’s a party crasher in the neural gala, leading to slowed reactions (no, it’s not just your slow internet), impaired memory (forgetting your own birthday kind of scene), and in extreme cases, permanent brain damage (because who needs all those brain cells, right?). Drink responsibly unless you plan to forget where you parked your car — permanently.",
      },
      {
        type: "subheading",
        text: "Kidney: “The Overworked Filters”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "These bean-shaped bodyguards get a tough time filtering out the happy hours. Alcohol dehydrates you (hello, never-ending thirst), disrupts the kidney’s ability to maintain the right balance of water and electrolytes, and increases the risk of kidney disease. It’s like making your kidneys run a marathon without training.",
      },
      {
        type: "subheading",
        text: "Liver: “The Silent Sufferer”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "Ah, the liver — the silent warrior against the alcohol onslaught. Regular heavy drinking leads to fatty liver, inflammation, and the dreaded cirrhosis. It’s like forcing your liver into a boxing ring, blindfolded and with both hands tied behind its back.",
      },
      {
        type: "subheading",
        text: "Intestine: “The Digestive Drama”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "Alcohol doesn’t just burn your wallet; it burns your intestines too. It messes with your digestion, leading to issues like gastritis and even ulcers. Think of it as throwing a spicy curry party in your gut, but everyone’s invited, and nobody’s happy.",
      },
      {
        type: "subheading",
        text: "Libido: “The Love Dampener”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "While a drink might seem like a love potion, it’s often a romance dampener. Excessive drinking can lead to decreased libido and performance issues. It’s like promising a romantic Bollywood dance but ending up with an awkward shuffle.",
      },
      {
        type: "subheading",
        text: "Relationships: “The Social Rollercoaster”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "Alcohol might be the life of the party, but it’s also the instigator of arguments and misunderstandings. It’s the uninvited guest that turns a friendly chat into a dramatic soap opera episode. Handle with care, unless you’re auditioning for a reality show.",
      },
      {
        type: "subheading",
        text: "Muscles and Joints: “The Unwelcomed Workout”",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Take:",
        text: "Ever felt achy after a drinking spree? That’s alcohol giving your muscles and joints an unwanted workout. It can lead to inflammation and weakness, like forcing your body into a gym session after a marathon. Remember, your muscles need TLC, not TLC (Tequila, Lime, and Cointreau).",
      },
      {
        type: "heading",
        text: "Safe Alcohol Consumption Limits and Benefits",
      },
      {
        type: "paragraph",
        text: "While Dry January focuses on abstinence, it’s also essential to discuss safe drinking limits. According to Dr. Gupta, ‘Moderate drinking, defined as up to one drink per day for women and two for men, might have certain heart benefits and can be part of a healthy lifestyle.’ However, he emphasizes that these benefits do not encourage non-drinkers to start drinking.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:4800/format:webp/1*dM6434dr21QKI1IEf0n2pQ.png",
        alt: "Mocktails, your best friend.",
      },
      {
        type: "heading",
        text: "Mocktails: Your New Best Friends",
      },
      {
        type: "paragraph",
        text: "Who says a Dry January has to be dull? Enter Mocktails — the superheroes of sobriety. These non-alcoholic beverages are not just about mixing fruit juices; they’re about creativity, flavor, and fun. From a spicy Virgin Mary to a tangy Nojito, mocktails offer the enjoyment of mixing and sipping drinks without the morning-after regrets.",
      },
      {
        type: "paragraph",
        text: "Let’s add some color and flavor to Dry January with a vibrant array of mocktail recipes.",
      },
      {
        type: "numbered heading",
        text: "1. Sunrise Surprise",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Orange & Mango",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Imagine a sunrise that doesn’t involve you waking up early. This mocktail combines fresh orange juice and mango puree, topped with a splash of soda. It’s like sipping on a tropical vacation, minus the sunburn.”",
      },
      {
        type: "numbered heading",
        text: "2. Berry Blast",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Red & Mixed Berries",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“It’s a berry explosion without the cleanup. Muddle some fresh strawberries, raspberries, and blueberries, mix in lemonade, and voila! It’s like a fruit salad went to a party in a glass.”",
      },
      {
        type: "numbered heading",
        text: "3. Green Garden Delight",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Green & Kiwi",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Who knew being green could taste this good? Blend kiwi with mint and a hint of lime. It’s refreshing, tangy, and will make you feel like you’re on a health retreat (but more fun).”",
      },
      {
        type: "numbered heading",
        text: "4. Tropical Sunset",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Yellow & Pineapple",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Bring the beach to your balcony with this pineapple paradise. Mix pineapple juice, coconut water, and a dash of grenadine. It’s like a sunset in a glass, minus the sand in your shoes.”",
      },
      {
        type: "numbered heading",
        text: "5. Purple Rain",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Purple & Grapes",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“This grape-infused wonder is royalty in a glass. Muddle grapes with a bit of sugar, add sparkling water and a squeeze of lime. It’s sophisticated, it’s elegant, it’s basically the prince of mocktails.”",
      },
      {
        type: "numbered heading",
        text: "6. Blue Lagoon",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Blue & Blueberries",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Dive into this deep blue sea of flavor. Blend blueberries with lemonade and a hint of mint. It’s like finding a treasure trove of taste.”",
      },
      {
        type: "numbered heading",
        text: "7. Ruby Red Refresher",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Pink & Watermelon",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Cool down with this watermelon wonder. Blend fresh watermelon, add a splash of ginger ale, and garnish with mint. It’s like a summer picnic in every sip.”",
      },
      {
        type: "numbered heading",
        text: "8. Coral Charm",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Coral & Peach",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Get charmed by this peachy sensation. Mix peach nectar with a bit of lemon juice and top with sparkling water. It’s sweet, it’s bubbly, and it’s blushing just for you.”",
      },
      {
        type: "numbered heading",
        text: "9. Golden Glory",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Gold & Passion Fruit",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Experience the richness of golden hour. Combine passion fruit pulp, orange juice, and a splash of soda. It’s exotic, it’s vibrant, and it’s your liquid gold.”",
      },
      {
        type: "numbered heading",
        text: "10. Midnight Mystery",
      },
      {
        type: "ul item",
        listHeading: "Color & Fruit:",
        text: "Dark & Blackcurrant",
      },
      {
        type: "ul item",
        listHeading: "Formis’ Twist:",
        text: "“Embrace the dark side deliciously. Mix blackcurrant syrup with soda and a twist of lemon. It’s mysterious, it’s intriguing, and it’s the perfect end to your day.”",
      },
      {
        type: "heading",
        text: "Conclusion: To Drink or Not to Drink",
      },
      {
        type: "paragraph",
        text: "As Dry January gains momentum in India, it invites us to reconsider our relationship with alcohol. Whether it’s a pause for health, a challenge for self-discipline, or just for fun, it’s a personal journey with public health implications. So, as you ponder over your next drink, remember, moderation is key, and sometimes, the best company for your glass might just be a fresh slice of lime and some water or maybe a honey drizzled green tea.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*3eT4wA2e9azrdP16E4DIOw.png",
        alt: "Conclusion",
      },
    ],
  },
];

export const featured = [
  // Blog 2
  {
    id: "medical-intelligence-systems-india",
    img: img2,
    title:
      "The Medical Intelligence Systems in India: A High-Tech Mirage or a Tangible Reality?",
    content: [
      {
        type: "heading",
        text: "From Bytes to Better Healthcare: The Promise of Medical Intelligence Systems in India",
      },
      {
        type: "paragraph",
        text: "In the bustling streets of India, where tradition meets modernity, a new player enters the stage — Medical Intelligence Systems (MIS). It’s a tale of transformation, where healthcare shakes hands with technology, promising a future that once seemed the stuff of science fiction. Imagine a world where health predictions are not just guesses but calculated forecasts, where treatments are tailored not just to the disease but to the individual. This is the world MIS promises.",
      },
      {
        type: "paragraph",
        text: "In a country that has leapfrogged from landlines straight to smartphones, the potential for a digital healthcare revolution is immense. Yet, as we stand on the brink of this technological renaissance, questions loom. How ready is India to embrace this change? Is the healthcare sector prepared to transform bytes of data into better healthcare outcomes?",
      },
      {
        type: "paragraph",
        text: "As we embark on this journey through the intricate world of MIS, let’s unfold the story of how these intelligent systems could redefine healthcare in India — a land where every byte of data could mean a new lease of life.",
      },
      {
        type: "quote",
        text: "“The highest education is that which does not merely give us information but makes our life in harmony with all existence.” — Rabindranath Tagore",
      },
      {
        type: "heading",
        text: "Decoding MIS (Medical Intelligence Systems): Bridging Healthcare with Human Well-being in India",
      },
      {
        type: "paragraph",
        text: "So, what exactly are Medical Intelligence Systems? At their core, MIS represent the pinnacle of healthcare and technology’s marriage. These systems are not just fancy software; they are the culmination of years of advancements in artificial intelligence (AI), machine learning, and big data analytics. They are akin to having a digital super-doctor — one that never sleeps, constantly learns, and is always ready with evidence-based advice.",
      },
      {
        type: "paragraph",
        text: "In simpler terms, MIS can be visualized as an intelligent assistant to doctors and healthcare consumers alike. They analyze vast amounts of data, learn from healthcare outcomes, and provide insights that were previously unfathomable. In a country like India, with its diverse and massive population, the implications are profound. From rural clinics to metropolitan hospitals, MIS has the potential to standardize and elevate the quality of care.",
      },
      {
        type: "paragraph",
        text: "However, the path of integrating MIS into India’s healthcare fabric is not without its hurdles. It’s a journey of navigating through the complex tapestry of data privacy concerns, infrastructural challenges, and the need for widespread digital literacy. As we delve deeper into the world of MIS, we will explore these challenges, the current state of adoption in India, and the transformative potential that lies ahead.",
      },
      {
        type: "quote",
        text: "“In the digital age, technology can be the bridge that connects healthcare with the essence of human well-being.” — FORMIS",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*M7F96g6TnSHYJ_KYEA-YQg.png",
        alt: "The Stark Reality",
      },
      {
        type: "heading",
        text: "The Stark Reality: Navigating the Roadblocks in India’s MIS Journey",
      },
      {
        type: "paragraph",
        text: "As we delve into the McKinsey report and other studies, the stark reality of MIS adoption in India becomes apparent. The integration of AI in healthcare, an essential component of MIS, faces significant barriers. A major impediment is the lack of multidisciplinary development and early involvement of healthcare staff in AI projects​​. The challenges don’t stop there. From the reluctance of doctors and healthcare consumers to embrace AI due to fear of errors​​, to concerns over consumers privacy and data authenticity​​, the roadblocks are many.",
      },
      {
        type: "paragraph",
        text: "A telling example is the struggle with data access and quality. In India, healthcare data is often fragmented, incomplete, and unrepresentative of the diverse population, impeding the development of accurate and universally applicable AI solutions​​. Add to this the ethical and security considerations, especially in the wake of cyberattacks on prominent institutions like AIIMS Delhi​​, and it becomes clear why MIS adoption is more a crawl than a sprint in India.",
      },
      {
        type: "quote",
        text: "“Healthcare is not just a science; it is an art of compassion and understanding.” — Ruskin Bond",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*gKm_fPu8ioRL0vt2yuv_ig.png",
        alt: "Transforming Challenges into Opportunities",
      },
      {
        type: "heading",
        text: "Transforming Challenges into Opportunities",
      },
      {
        type: "paragraph",
        text: "While challenges in the adoption of Medical Intelligence Systems (MIS) in India are evident, recent government initiatives suggest a shift towards a more optimistic scenario. The Ayushman Bharat Digital Mission (ABDM), launched by Prime Minister Narendra Modi in September 2021, marks a significant step in this direction. The mission’s cornerstone, the Ayushman Bharat Health Account (ABHA), provides a unique 14-digit number to each citizen, allowing secure and consent-based access to their health records across multiple systems and stakeholders​​​​.",
      },
      {
        type: "paragraph",
        text: "This initiative aligns with the National Health Policy (NHP) 2017, which emphasized the need for a holistic, comprehensive, and interoperable digital architecture in healthcare​​. The National Digital Health Blueprint (NDHB) further supports this vision, advocating a “Think Big, Start Small, Scale Fast” approach. It emphasizes the creation of a layered framework for digital health infrastructure, data hubs, and a consent-based system of Electronic Health Records (EHR) accessible to citizens and service providers​​.",
      },
      {
        type: "paragraph",
        text: "Moreover, NDHB places great importance on data ownership, ensuring that healthcare consumers own their EHRs while health facilities maintain this data under trust, safeguarding privacy, security, and confidentiality​​. Additionally, the Blueprint promotes health data analytics and medical research, enhancing the efficiency and effectiveness of healthcare governance and ensuring quality of care​​​​.",
      },
      {
        type: "paragraph",
        text: "One of the most crucial aspects of the Blueprint is its focus on interoperability, a prerequisite for developing integrated digital health services and establishing a continuum of care. This is achieved through the Health Information Exchange and National Health Informatics Standards, which enable and promote interoperability across various services and building blocks​​. The development of these components is facilitated through a federated model, involving collaboration between the centre, state, and health facilities, ensuring data remains primarily at the health facility level​​.",
      },
      {
        type: "quote",
        text: "“The future of healthcare lies in our ability to adapt, innovate, and embrace the possibilities of a connected world.” — Chetan Bhagat",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*MWRAjHLX-EVCBDVT2AJ6cw.png",
        alt: "Data-Driven Healthcare",
      },
      {
        type: "heading",
        text: "Data-Driven Healthcare: Empowering Healthcare Consumers with MIS",
      },
      {
        type: "paragraph",
        text: "Given these government initiatives, the dream of MIS for healthcare consumers in India is no longer distant but increasingly within grasp. The ABHA initiative, coupled with the NDHB framework, paves the way for a more connected, efficient, and personalized healthcare system. For the average healthcare consumer, this means access to a unified health record, increased transparency in healthcare services, and a more empowered role in their health management.",
      },
      {
        type: "paragraph",
        text: "The potential impact of MIS on healthcare outcomes in India is significant. For instance, a healthcare consumers with a chronic condition can now have their health data seamlessly integrated across various healthcare providers, ensuring continuity and personalization of care. This interoperable and data-driven approach not only improves the accuracy of diagnoses and treatment plans but also enhances the overall efficiency of the healthcare system.",
      },
      {
        type: "paragraph",
        text: "In conclusion, while the journey towards full-scale adoption of MIS in India faces challenges, government-led initiatives such as ABHA and the NDHB are key steps towards overcoming these obstacles. These initiatives, focusing on digital infrastructure, data standards, and consumer-centric approaches, are transforming the landscape of healthcare in India, turning the promise of MIS into a tangible reality for every healthcare consumer.",
      },
      {
        type: "quote",
        text: "“The beauty of healthcare is that it can transform lives, bringing hope where there was once despair.” — Arundhati Roy",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*6zVCkcflhJ2RVDELpGaf6A.png",
        alt: "The Indian Healthcare Professional’s Dilemma with MIS",
      },
      {
        type: "heading",
        text: "The Indian Healthcare Professional’s Dilemma with MIS",
      },
      {
        type: "paragraph",
        text: "The integration of Medical Intelligence Systems (MIS) in India’s healthcare landscape brings with it a unique set of challenges and opportunities for healthcare professionals. Their perspective is crucial in understanding the adoption and effective utilization of these technologies.",
      },
      {
        type: "subheading",
        text: "Voices from the Ground: Perspectives of Indian Healthcare Professionals",
      },
      {
        type: "paragraph",
        text: "Healthcare professionals in India express mixed feelings about MIS and AI. Dr. Anjali Sharma, a cardiologist from New Delhi, shares, “The potential of MIS in revolutionizing healthcare consumer care is immense. However, the fear of technology replacing the human element in healthcare is a concern among many of my peers.”",
      },
      {
        type: "paragraph",
        text: "Similarly, Dr. Rajesh Singh, a general practitioner from Mumbai, notes, “While I am excited about the advancements MIS brings, I am also apprehensive about my ability to keep up with these rapid technological changes.”",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*cuFBDD07-1ohynxZ0WVhlA.png",
        alt: "MIS",
      },
      {
        type: "heading",
        text: "MIS: From Apprehension to Acceptance through Training and Education. Bridging The Knowledge Gap.",
      },
      {
        type: "paragraph",
        text: "Recognizing these concerns, there is a growing emphasis on training and education initiatives to bridge the knowledge gap. Medical institutions and healthcare organizations are increasingly incorporating AI and data analytics into their curricula and continuing education programs. For instance, the Indian Medical Association (IMA) has started offering workshops and seminars on the basics of AI and its applications in healthcare.",
      },
      {
        type: "paragraph",
        text: "Moreover, collaborations between technology companies and medical institutions aim to provide hands-on training and real-world applications of MIS. These initiatives not only enhance the technical skills of healthcare professionals but also address their apprehensions by demonstrating the supportive role of MIS in enhancing, rather than replacing, human-driven healthcare.",
      },
      {
        type: "paragraph",
        text: "As Dr. Sharma concludes, “Education and training in MIS are not just about understanding the technology; they are about integrating it into our practice in a way that enhances our ability to care for our healthcare consumer.”",
      },
      {
        type: "paragraph",
        text: "RIn summary, while there is a sense of apprehension among Indian healthcare professionals regarding the rapid integration of MIS, concerted efforts in training and education are paving the way for a smoother transition. These initiatives are crucial in ensuring that healthcare professionals are equipped to harness the full potential of MIS, thereby enhancing healthcare consumer care and healthcare delivery in India.",
      },
      {
        type: "quote",
        text: "“Healthcare technology, when harnessed with compassion, has the power to heal not just bodies but souls.” — Arvind Adiga",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*dS8QTg7HKNlA2ZMg19rz2A.png",
        alt: "Democratizing Advanced Medical Technology with FORMIS",
      },
      {
        type: "heading",
        text: "Democratizing Advanced Medical Technology with FORMIS",
      },
      {
        type: "paragraph",
        text: "FORMIS, standing for ‘Forging Optimized and Responsive Medical Intelligence Systems,’ is at the vanguard of revolutionizing healthcare technology in India. Built on the cutting-edge foundation of Federated Learning Algorithms, FORMIS offers a unique approach to MIS. This innovative framework allows for the collaborative yet confidential analysis of data, ensuring consumer privacy while leveraging collective insights for improved healthcare outcomes.",
      },
      {
        type: "paragraph",
        text: "The graphical sophistication of FORMIS’s UI/UX design sets a new standard in user experience. It’s not just about aesthetics; it’s about creating an intuitive, user-friendly interface that simplifies the adoption and learning curve for healthcare professionals. This design philosophy ensures that FORMIS is not just a tool for the tech-savvy but is accessible to all, democratizing the benefits of advanced medical technology.",
      },
      {
        type: "paragraph",
        text: "Crucially, FORMIS aligns with the Government of India’s validation standards, promising a robust and mature system that meets the highest benchmarks of quality and reliability. The integration of FORMIS into the healthcare sector represents a significant leap forward, offering a platform that is not only technologically advanced but also secure, compliant, and tailored to the unique needs of the Indian healthcare landscape.",
      },
      {
        type: "paragraph",
        text: "FORMIS not only offers advanced technology but also promotes proactive and continuous care delivery to healthcare consumers. With a suite of engagement tools and automated care features, FORMIS ensures that individuals are not passive recipients of healthcare but active participants in their well-being. This comprehensive approach places the power of healthcare management in the hands of consumers, enhancing their experience while advancing the capabilities of Medical Intelligence Systems in India.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*8N9VKqW7_CdEpvVsfiF4IA.png",
        alt: "Conclusion",
      },
      {
        type: "heading",
        text: "Conclusion: From Promise to Reality - Embracing MIS for a Transformed Healthcare Landscape in India",
      },
      {
        type: "paragraph",
        text: "In conclusion, Medical Intelligence Systems (MIS) hold immense promise for revolutionizing healthcare delivery in India, particularly in the context of its diverse and massive population. These systems have the potential to standardize and elevate the quality of care, providing personalized and evidence-based treatment plans. However, the path of integrating MIS into India’s healthcare fabric is not without its hurdles, including concerns over data privacy, infrastructural challenges, and the need for widespread digital literacy.",
      },
      {
        type: "paragraph",
        text: "Despite these challenges, government initiatives such as the Ayushman Bharat Digital Mission (ABDM) and the National Digital Health Blueprint (NDHB) are paving the way for a smoother transition towards MIS adoption. These initiatives focus on digital infrastructure, data standards, and consumer-centric approaches, transforming the landscape of healthcare in India.",
      },
      {
        type: "paragraph",
        text: "Recognizing the concerns of healthcare professionals, concerted efforts in training and education are bridging the knowledge gap and addressing apprehensions surrounding MIS. These initiatives are crucial in ensuring that healthcare professionals are equipped to harness the full potential of MIS, thereby enhancing healthcare consumer care and healthcare delivery in India.",
      },
      {
        type: "paragraph",
        text: "As MIS technology continues to advance and government initiatives gain momentum, the promise of MIS for healthcare consumers in India is no longer distant but increasingly within grasp. MIS has the potential to empower healthcare consumers, placing the power of healthcare management in their hands and transforming healthcare into a more proactive and continuous care delivery system.",
      },
      {
        type: "paragraph",
        text: "In this journey of MIS adoption, FORMIS stands as a pioneering platform that offers a unique approach to MIS. Built on the cutting-edge foundation of Federated Learning Algorithms, FORMIS enables collaborative yet confidential analysis of data, ensuring consumer privacy while leveraging collective insights for improved healthcare outcomes. With its user-friendly interface, compliance with government standards, and focus on proactive and continuous care delivery, FORMIS is poised to play a significant role in democratizing advanced medical technology and transforming healthcare in India.",
      },
      {
        type: "paragraph",
        text: "India, with its commitment to technological innovation, is well-positioned to harness the power of MIS and revolutionize healthcare for its citizens. FORMIS, alongside other advancements in MIS technology, holds the key to unlocking a future where healthcare is personalized, predictive, and accessible to all.",
      },
    ],
  },

  // Blog 3
  {
    id: "data-driven-strategies-healthcare-india",
    img: img3,
    title:
      "How Data-Driven Strategies are Transforming the Future of Healthcare in India.",
    desc: "“When Numbers Speak, Lives Change — A Revolution in Indian Healthcare”",
    content: [
      {
        type: "subheading",
        text: "“When Numbers Speak, Lives Change — A Revolution in Indian Healthcare”",
      },
      {
        type: "heading",
        text: "Introduction: The Urgency of Now",
      },
      {
        type: "quote",
        text: "“Injustice anywhere is a threat to justice everywhere.” — Martin Luther King Jr.",
      },
      {
        type: "paragraph",
        text: "In India, the healthcare system is a paradox. On one hand, we have world-class medical facilities attracting medical tourism; on the other, we have villages where even basic healthcare is a luxury. This dichotomy isn’t just an observation; it’s a glaring injustice. And when we talk about injustice in healthcare, we’re essentially talking about lives hanging in the balance. So, let’s not mince words here: the need for a revolution in Indian healthcare is not just urgent; it’s a matter of life and death. This isn’t hyperbole; it’s a call to arms.",
      },
      {
        type: "paragraph",
        text: "The good news? A revolution is already underway, and it’s being led by data. Yes, you heard it right — numbers, algorithms, and data analytics are the new weapons in our arsenal against healthcare disparity. When numbers speak, lives change. And in this article, we’ll delve into how data-driven strategies are not just a buzzword but a lifeline for millions. Buckle up; it’s going to be an enlightening ride.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*aKDdM3xRpeb6NqEQKsRJSg.png",
        alt: "The Current State of Healthcare in India",
      },
      {
        type: "heading",
        text: "The Current State of Healthcare in India",
      },
      {
        type: "quote",
        text: "“The Good, The Bad, and The Ugly” “The only thing necessary for the triumph of evil is for good men to do nothing.” — Edmund Burke",
      },
      {
        type: "paragraph",
        text: "Let’s start with the good. India is a powerhouse when it comes to medical professionals. We produce some of the finest doctors and nurses who are in demand globally. Our pharmaceutical industry is robust, and we’re often termed the ‘pharmacy of the world.’ But let’s not pat ourselves on the back just yet.",
      },
      {
        type: "paragraph",
        text: "Now, the bad. Our healthcare infrastructure is woefully inadequate. The doctor-patient ratio is abysmal, especially in rural areas. Basic amenities like clean water and sanitation, which are the first line of defense against diseases, are still a dream for many.",
      },
      {
        type: "paragraph",
        text: "And the ugly? The glaring disparities. A person in a metro city has access to world-class healthcare, while someone in a village might have to travel miles for basic medical attention. This isn’t just a gap; it’s a chasm. And mind you, we’re not assuming this; the numbers back it up. According to the National Health Profile 2019, public health spending is still around 1.28% of the GDP. That’s not just low; it’s embarrassingly low.",
      },
      {
        type: "heading",
        text: "Real-time Case Studies: The Gap Between Urban and Rural Healthcare",
      },
      {
        type: "paragraph",
        text: "Take the case of telemedicine startups that have mushroomed in urban areas. They offer consultations with specialists at the click of a button. Now contrast this with villages where even general physicians are scarce. It’s like comparing apples and asteroids; they’re not even in the same universe.",
      },
      {
        type: "subheading",
        text: "“A Tale of Two Indias”: The Healthcare Divide",
      },
      {
        type: "paragraph",
        text: "The healthcare scenario in India is best described as a tale of two countries. One is progressive, technologically advanced, and data-driven. The other is stuck in a time warp, devoid of even basic healthcare data. The divide is not just geographical; it’s technological, and it’s costing lives.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*YaL_s-K4-lKBb2FFFgf77w.png",
        alt: "Data Matters",
      },
      {
        type: "heading",
        text: "Why Data Matters",
      },
      {
        type: "quote",
        text: "“The Power of Numbers: From Predictive to Prescriptive” “In God we trust; all others bring data.” — W. Edwards Deming",
      },
      {
        type: "paragraph",
        text: "Data is the new oil, they say. But in healthcare, data is not just valuable; it’s vital. Predictive analytics can forecast outbreaks, while prescriptive analytics can offer solutions to mitigate them. Data can identify patterns that the human eye might overlook. For instance, spikes in the sale of over-the-counter medications could indicate a potential outbreak of a disease.",
      },
      {
        type: "subheading",
        text: "Real-time Case Studies: How Data Saved Lives",
      },
      {
        type: "paragraph",
        text: "Consider the state of Kerala, which successfully used data analytics to combat the Nipah virus outbreak in 2018. Real-time data helped in contact tracing, isolating affected individuals, and containing the virus effectively.",
      },
      {
        type: "quote",
        text: "“Numbers Don’t Lie, People Do”: Debunking Myths About Data-Driven Healthcare",
      },
      {
        type: "paragraph",
        text: "There’s a misconception that data-driven healthcare is all about cold numbers and lacks a human touch. That’s as absurd as saying that using a GPS makes you less of a traveler. Data complements human expertise; it doesn’t replace it.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*WQ68rhQ52Sh9PYuxpAsOTw.png",
        alt: "The Rise of HealthTech",
      },
      {
        type: "heading",
        text: "The Rise of HealthTech",
      },
      {
        type: "quote",
        text: "“The Intersection of Technology and Healthcare” “The best way to find yourself is to lose yourself in the service of others.” — Mahatma Gandhi",
      },
      {
        type: "paragraph",
        text: "Technology has always been a catalyst for change, and in healthcare, it’s no different. HealthTech in India is not just a sector; it’s a movement. From AI-powered diagnostics to blockchain-enabled patient records, technology is making healthcare more efficient, accessible, and personalized.",
      },
      {
        type: "subheading",
        text: "Real-time Case Studies: HealthTech Startups Making a Difference",
      },
      {
        type: "paragraph",
        text: "In the HealthTech landscape, numerous startups are making waves by offering innovative solutions. From simplifying the appointment-booking process to leveraging AI for diagnostics, these companies are transforming traditional healthcare practices. They’re not just creating profitable businesses; they’re addressing critical gaps in healthcare delivery, especially in underserved areas. These startups exemplify how technology can be a force for good, improving both healthcare access and outcomes.",
      },
      {
        type: "subheading",
        text: "“If You’re Not Online, You’re Offline”: The Digital Transformation of Healthcare",
      },
      {
        type: "paragraph",
        text: "The pandemic has accelerated the digital transformation of healthcare like never before. Telemedicine, once a novelty, has become a necessity. If your healthcare service isn’t online, you’re not just behind the times; you’re practically prehistoric.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*flGb1Cq0cNtZJbYx8-4Urw.png",
        alt: "Challenges and Roadblocks",
      },
      {
        type: "heading",
        text: "Challenges and Roadblocks",
      },
      {
        type: "quote",
        text: "“Data Privacy and Ethical Concerns” “With great power comes great responsibility.” — Voltaire",
      },
      {
        type: "paragraph",
        text: "Data-driven healthcare is powerful, but it’s not without its pitfalls. Data privacy is a significant concern. Your medical history is as personal as it gets, and the thought of it being mishandled is nothing short of a nightmare.",
      },
      {
        type: "subheading",
        text: "Real-time Case Studies: When Data Goes Wrong",
      },
      {
        type: "paragraph",
        text: "Remember the Aadhaar data leak scandal? It served as a wake-up call that even the most secure systems are vulnerable. When it comes to healthcare data, the stakes are even higher.",
      },
      {
        type: "subheading",
        text: "“The Road to Hell is Paved with Good Intentions”: The Unintended Consequences of Data-Driven Healthcare",
      },
      {
        type: "paragraph",
        text: "Data analytics is a double-edged sword. While it can save lives, it can also lead to profiling and discrimination. Imagine being denied insurance based on your genetic predisposition to a particular disease. The ethical implications are staggering.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*qwwWpNER_rAmGU_NrnmmNQ.png",
        alt: "Challenges and Roadblocks",
      },
      {
        type: "heading",
        text: "Formis Health Technologies: The Vanguard of a Data-Driven Revolution",
      },
      {
        type: "quote",
        text: "“A New Dawn in Healthcare is Not Just on the Horizon; It’s Inevitable” “The ones who are crazy enough to think they can change the world, usually do.” — Steve Jobs",
      },
      {
        type: "paragraph",
        text: "Listen up, because what we’re about to say isn’t just important; it’s necessary. While the healthcare industry is busy patting itself on the back for minor advancements, we at Formis are rolling up our sleeves for something monumental. You see, we’re not interested in joining the data-driven healthcare narrative; we’re here to redefine it. And this isn’t some lofty aspiration; it’s a promise etched in algorithms and data points.",
      },
      {
        type: "subheading",
        text: "The Formis Solution: Not Just a Glimpse, But a Vision of the Future",
      },
      {
        type: "paragraph",
        text: "We’re in the trenches, meticulously crafting solutions that will make you wonder how you ever managed healthcare without them. Remote patient monitoring? Predictive analytics? These aren’t buzzwords for us; they’re the bread and butter of a healthcare revolution. And let’s be clear: we’re not in the business of creating features; we’re in the business of saving lives.",
      },
      {
        type: "subheading",
        text: "“Data is Good, Decisions are Better”: The Formis Doctrine",
      },
      {
        type: "paragraph",
        text: "Let’s get one thing straight: Data is the opening act, but the headliner is actionable decisions. We’re not gathering data for the sake of pretty charts; we’re turning it into a symphony of insights that healthcare providers can dance to. And this isn’t a pipe dream; it’s a calculated strategy backed by years of research and development.",
      },
      {
        type: "subheading",
        text: "“The Formis Way”: A Blueprint So Revolutionary, It Should Come with a Spoiler Alert",
      },
      {
        type: "paragraph",
        text: "We’re not here to fit into the healthcare landscape; we’re here to reshape it. Our approach isn’t just holistic; it’s reimagined. We’re not offering a service; we’re offering a transformation. And this isn’t just our mission; it’s our vocation.",
      },
      {
        type: "heading",
        text: "Conclusion: The Purpose Behind the Words",
      },
      {
        type: "quote",
        text: "“A Revolution Isn’t Measured in Words but in Lives Transformed” “To accomplish great things, we must not only act, but also dream; not only plan, but also believe.” — Anatole France",
      },
      {
        type: "paragraph",
        text: "If you’ve made it this far, it’s not because you have time to spare; it’s because you recognize the urgency of now. This article isn’t just a collection of words and ideas; it’s a call to action. A call to reimagine, redefine, and revolutionize healthcare in India. We’ve laid out the problems, showcased the potential of data-driven solutions, and offered a glimpse into the groundbreaking work we’re doing at Formis Health Technologies.",
      },
      {
        type: "paragraph",
        text: "But let’s not forget why we’re here. This isn’t about technology for technology’s sake; it’s about people. It’s about transforming healthcare from a service to a right, from a business to a mission. So as you close this tab and go about your day, remember: the revolution will be data-driven, and it starts with you.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1100/format:webp/1*hHjkPrJtX5w09HsFSOgWHw.png",
        alt: "Challenges and Roadblocks",
      },
      {
        type: "quote",
        text: "The Future of Healthcare: A Unified, Proactive, and Tailored Approach “The Future is Not an Extension of the Present; It’s a Reimagined Reality” “The best way to predict the future is to invent it.” — Alan Kay",
      },
      {
        type: "paragraph",
        text: "The future of healthcare isn’t fragmented; it’s unified. It’s not reactive; it’s proactive. It’s not impersonal; it’s tailored to each individual healthcare consumer. And let’s be crystal clear: the time to embrace this reimagined future is not tomorrow, not ‘someday,’ but right now. This isn’t a lofty ideal; it’s a calculated, data-driven strategy that we at Formis are committed to bringing to life.",
      },
      {
        type: "paragraph",
        text: "So hop on board this healthcare revolution. It’s not just a wish; it’s a necessity. And trust us, it’s a ride worth taking.",
      },
    ],
  },
];

export const blogPageData = [
  // Blog 4
  {
    id: "chai-cricket-custom-care",
    img: img4,
    title: "Chai, Cricket, and Custom Care: The Future of Indian Healthcare",
    desc: "The Paradigm Shift in Healthcare",
    content: [
      {
        type: "heading",
        text: "The Paradigm Shift in Healthcare",
      },
      {
        type: "paragraph",
        text: "Ah, healthcare in India, the land where Ayurveda was born but where you're more likely to get a recommended medicines before the doctor even hears your full list of symptoms. But don't lose hope; change is in the air, thicker than Delhi's smog. We're finally moving from a \"one-size-fits-all, and that size is usually wrong\" system to a more nuanced, personalized approach that recognizes your individuality. However, this change still needs a booster shot of innovation, technology, and an overhaul of our mindsets. This isn't merely a suggestion, mind you, but rather the clarion call of the future of Indian healthcare. Just like in a cricket match, we need to bat for change, bowl out the old methods, and catch the opportunities technology offers.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Wg8hqwEQcziAtQ37H5icKg.jpeg",
        alt: "The Paradigm Shift in Healthcare",
      },
      {
        type: "subheading",
        text: "Quote:",
      },
      {
        type: "paragraph",
        text: "As Ratan Tata wisely said, \"I don't believe in taking right decisions. I take decisions and then make them right. \"It's high time healthcare in India took a decision to innovate and made it right for everyone involved.",
      },
      {
        type: "subheading",
        text: "Data Point:",
      },
      {
        type: "paragraph",
        text: "In India, medical errors contribute to an estimated 5.2 million deaths annually. If this were a Bollywood movie, it would be a tragedy directed by Karan Johar—high on drama but devastating in its impact.",
      },
      {
        type: "subheading",
        text: "The Indian Context:",
      },
      {
        type: "paragraph",
        text: "In a country as diverse as India, where healthcare practices range from high-tech surgeries in metro cities to traditional herbal treatments in rural areas, a one-size-fits-all approach is not just ineffective; it's borderline ludicrous. This necessitates a unique, flexible approach that caters to the distinct needs of the vast Indian populace, marking a shift from blanket healthcare solutions to personalized, effective care.",
      },
      {
        type: "heading",
        text: "The Necessity for Personalization",
      },
      {
        type: "paragraph",
        text: "Imagine sitting in a clinic that's as crowded as a Mumbai local train, waiting for your turn to see the doctor. When you finally get those precious seven minutes, you're given a recommended medicines faster than a T20 cricket over. Personalization in healthcare isn't a luxury; it's as essential as your daily cup of chai. It isn't about receiving care as per a generic checklist, rather it's about understanding your unique physiological composition, lifestyle habits, genetic predispositions, and then customizing healthcare purely for you. The future of Indian healthcare lies with a tea stall vendor having easy access to his genetic map and cricket enthusiasts monitoring their health via wearable tech, all while watching their favorite game. The era of 'Chai, Cricket, and Custom Care' awaits us.",
      },
      {
        type: "subheading",
        text: "Quote:",
      },
      {
        type: "paragraph",
        text: 'As the brilliant A.P.J. Abdul Kalam stated, "Excellence is a continuous process and not an accident." Our healthcare system needs to realize that excellence in patient care comes from personalized, continuous effort, not generic solutions.',
      },
      {
        type: "subheading",
        text: "Example:",
      },
      {
        type: "paragraph",
        text: "Let's talk about Raj. Raj has chronic migraines and was given the standard paracetamol treatment. A few months later, Raj is not only battling migraines but also liver issues due to medication overuse. Had his treatment been personalized—maybe through Ayurvedic or naturopathic approaches—he might be living a different story.",
      },
      {
        type: "subheading",
        text: "Data Point:",
      },
      {
        type: "paragraph",
        text: "Studies indicate that personalized healthcare could reduce the average length of hospital stays by up to 20% in India. That's not just a win-win; it's like hitting a six off the last ball.",
      },
      {
        type: "subheading",
        text: "The Indian Context:",
      },
      {
        type: "paragraph",
        text: "In India, where family history and lifestyle factors like diet and stress levels can vary widely, personalization is not just a buzzword; it's a necessity. From the spices in our food to the way we celebrate our festivals, diversity is in our DNA. Shouldn't our healthcare be just as diverse?Given the complexity of our genetic and environmental variations, a personalized approach to healthcare can help us better understand and manage health conditions unique to our population, leading to improved outcomes and a healthier society.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*bJjV-1YTb4X1zimhRo371Q.jpeg",
        alt: "Personalization",
      },
      {
        type: "heading",
        text: "The Role of Technology",
      },
      {
        type: "paragraph",
        text: "Ah, technology, the same force that has half of India making reels on Instagram can actually be harnessed to save lives. We're talking about AI algorithms so advanced they could probably predict the next monsoon better than the Met department.From electronic health records to telemedicine, this digital revolution represents a whole new frontier in Indian healthcare. Imagine a time where data and digital innovation build a health system that feels like it's designed specifically for cricket-loving, chai-drinking, hard-working millions of us.",
      },
      {
        type: "image",
        src: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*lZwdyVQz6ZR8DY3KdXYpYA.png",
        alt: "Technology",
      },
      {
        type: "subheading",
        text: "Quote:",
      },
      {
        type: "paragraph",
        text: 'As Sundar Pichai, an Indian leading the tech world, said, "A person who is happy is not because everything is right in his life, he is happy because his attitude towards everything in his life is right." If technology can make our lives better, imagine what the right attitude towards tech can do for healthcare.',
      },
      {
        type: "subheading",
        text: "Example:",
      },
      {
        type: "paragraph",
        text: "Meet Priya, a diabetic who used to dread her daily finger pricks. Now, she wears a smart band that not only monitors her sugar levels but also adjusts her insulin pump accordingly. It's like having a personal healthcare assistant, minus the chit-chat.",
      },
      {
        type: "subheading",
        text: "Data Point:",
      },
      {
        type: "paragraph",
        text: "The digital healthcare market in India is expected to reach $4.4 billion by 2024. That's billion with a 'B,' as in \"Bapu would be proud.\"",
      },
      {
        type: "subheading",
        text: "The Indian Context:",
      },
      {
        type: "paragraph",
        text: "In a country where the urban-rural divide is as stark as the difference between a masala dosa and a paneer tikka, technology has the potential to bridge the gap. Telemedicine can bring quality healthcare to remote villages, while AI can help overburdened doctors in cities manage their enormous caseloads more effectively.But what about disease prevention? With technologies like IoT devices and wearables, we can create data-driven health interventions. Combined with Big data and Machine learning, we can start predicting and preventing diseases before they take a serious hold, moving us from a reactive to a proactive health system. And that's not just the future, that's the inevitable and that’s what Formis Health Technologies is bringing.",
      },
      {
        type: "subheading",
        text: "The Fact:",
      },
      {
        type: "link",
        href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8136677/",
        text: "India has a high prevalence of infectious diseases, including tuberculosis and vector-borne diseases like dengue and malaria.",
      },
      {
        type: "heading",
        text: "Summary:",
      },
      {
        type: "paragraph",
        text: "Formis' unfiltered, no-holds-barred take on \"Chai, Cricket, and Personalised Care: The Future of Indian Healthcare.\" We're not just narrating the story; we're the protagonists, the plot-twisters, and yes, the disruptors.",
      },
      {
        type: "inline subheading",
        text: "1. The Paradigm Shift in Healthcare:",
      },
      {
        type: "inline paragraph",
        text: "Let's cut the fluff. The healthcare system in India needs more than a facelift; it needs a full-body transformation. And guess who's holding the scalpel? That's right, we at Formis are injecting the much-needed adrenaline shot into the veins of Indian healthcare.",
      },
      {
        type: "break",
      },
      {
        type: "break",
      },
      {
        type: "inline subheading",
        text: "2. The Necessity for Personalization:",
      },
      {
        type: "inline paragraph",
        text: `Personalization in healthcare isn't a "nice-to-have"; it's a "must-have-or-else." Take our friend Raj, for instance. A more personalized approach could've saved him from a medical merry-go-round. So, we're rolling out the red carpet for the era of 'Chai, Cricket, and Custom Care.' Buckle up!`,
      },
      {
        type: "break",
      },
      {
        type: "break",
      },
      {
        type: "inline subheading",
        text: "3. The Role of Technology:",
      },
      {
        type: "inline paragraph",
        text: "We're not just riding the tech wave; we're the ones creating it. From AI to IoT, we're using every acronym in the book to revolutionize healthcare. And this is just the trailer; the full movie is yet to come.",
      },
      {
        type: "break",
      },
      {
        type: "break",
      },
      {
        type: "inline subheading",
        text: "4. The Fact:",
      },
      {
        type: "inline paragraph",
        text: "Let's get real. India is a hotbed for infectious diseases. The clock is ticking, and we don't have the luxury of time.",
      },
      {
        type: "break",
      },
      {
        type: "break",
      },
      {
        type: "paragraph",
        text: "So, what's your next move? Simple. Get off the bench and onto the field. Join our waitlist and be part of this seismic shift. Because at Formis, we're not just making waves; we're creating a tsunami of change. And you don't want to miss it.",
      },
      {
        type: "heading",
        text: "Your Role in This Revolution: No More Benchwarmers, Please!",
      },
      {
        type: "paragraph",
        text: "Look, we at Formis aren't just sitting around sipping chai and watching cricket. We're on the field, rewriting the rulebook of Indian healthcare. And you, dear reader, are not just a spectator; you're a key player in this game-changing inning.",
      },
    ],
  },

  // Blog 5
  {
    id: "fragmentation-unity-patient-care",
    img: img5,
    title:
      "From Fragmentation to Unity: How a Unified Ecosystem is Transforming Patient Care in India.",
    desc: "The Formis Blueprint: Orchestrating a Healthcare Revolution",
    content: [
      {
        type: `link`,
        href: `https://www.heyformis.com/`,
        text: `The Formis Blueprint: Orchestrating a Healthcare Revolution`,
      },
      {
        type: `heading`,
        text: `Introduction: The Imperative for Unity`,
      },
      {
        type: `paragraph`,
        text: `“Healthcare is not a privilege; it’s a right,” says Dr. Ravi Patel, a leading healthcare advocate in India.`,
      },
      {
        type: `paragraph`,
        text: `Ah, fragmented healthcare — the gift that keeps on giving, isn’t it? According to a study by the Indian Journal of Public Health, nearly 63 million people face poverty due to healthcare costs each year. In our last tête-à-tête, we dissected this labyrinthine mess.`,
      },
      {
        type: `paragraph`,
        text: `In this manifesto, we’re not just pivoting; we’re pole-vaulting from problem identification to solution architecture — a unified healthcare ecosystem. This isn’t some pie-in-the-sky fantasy; it’s a concrete blueprint for a healthcare revolution.`,
      },
      {
        type: `paragraph`,
        text: `Imagine a world where Dr. Sharma knows not just your name but your entire medical history. Say goodbye to redundant tests and surprise medical bills that make you question your life choices.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*XzyDJ-yGOK6dVdDtNZ5HMA.jpeg`,
        alt: `Technology becomes your healthcare companion, ensuring an unbroken journey of care.`,
      },
      {
        type: `heading`,
        text: `The Architecture of Unity: More Than Just Infrastructure`,
      },
      {
        type: `paragraph`,
        text: `“Interoperability is not the future; it’s a necessity,” states Dr. Anjali Sharma, a pioneer in healthcare technology. We’re talking about a meticulously designed, interoperable architecture that goes beyond mere infrastructure. According to NITI Aayog, less than 30% of Indian healthcare providers have adopted Electronic Health Records (EHRs).`,
      },
      {
        type: `paragraph`,
        text: `In a unified system, these records are shared seamlessly, allowing for better-coordinated care and eliminating redundant tests. We’re not just connecting dots; we’re weaving a tapestry of healthcare excellence. e.But it’s not just about technological sophistication — it’s about crafting a person-focused care delivery model.`,
      },
      {
        type: `paragraph`,
        text: `Imagine instant access to complete, accurate health records, fostering a healthcare continuum where the emphasis is on preventive care rather than a reactive approach. Such a system can transform the face of healthcare, not only for patients but for physicians, specialists, and pharmacies involved in the delivery of care too. Expedited diagnosis, recommended medicines, referrals, and ongoing management of chronic conditions — everything becomes streamlined in a unified ecosystem.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*8CcRKu-bQNUQatXbxOwCqw.jpeg`,
        alt: `The Neural Network that serves as your personal health assistant, diagnosing risks and identifying persistent issues, anytime and anywhere. That’s the power of Formis Health Technologies.`,
      },
      {
        type: `heading`,
        text: `Data-Driven Decisions: The Lifeblood of Healthcare`,
      },
      {
        type: `paragraph`,
        text: `“Data is the new oil,” says Mukesh Ambani, and in healthcare, it’s no different. According to a report by PwC India, the use of data analytics in healthcare could improve outcomes by almost 30%.`,
      },
      {
        type: `paragraph`,
        text: `In today’s digital age, every test, every diagnosis, every treatment plan — it’s all stored and analyzed to improve patient outcomes. And in a unified healthcare ecosystem, this data isn’t siloed. It’s shared, analyzed, and used to inform decisions at every level of care.`,
      },
      {
        type: `paragraph`,
        text: `Think of it as the top notch Board of Directors, guiding a ship through stormy seas — only this Board doesn’t demand outrageous salaries or argue over golf club memberships. They silently steer healthcare towards progress using patterns and trends, predicting outcomes and informing decisions. They’re not people; they’re data points. Collected and analyzed, these data points contribute to a stronger, unified healthcare ecosystem that functions not just on intuition, but on concrete evidence.`,
      },

      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*YtTWEwUVocVrJFENylHb9Q.jpeg`,
        alt: `Your health history, your lifestyle, and your body — these have always been your data, and they should inform your diagnostics. Simple, Smart, and Human-Centered: that’s the essence of Formis Health Technologies.`,
      },
      {
        type: `heading`,
        text: `The Role of AI and Machine Learning: Beyond Buzzwords`,
      },
      {
        type: `paragraph`,
        text: `“AI is the new electricity,” claims Dr. Andrew Ng, and in healthcare, it’s the new lifeline. According to Accenture, AI applications can save up to $150 billion annually by 2026 in the healthcare sector. These technologies have the potential to revolutionize healthcare, offering powerful tools for diagnosis, treatment, and patient care. By analyzing vast amounts of data, AI and Machine Learning can help healthcare providers make faster, more accurate decisions, improving patient outcomes and reducing costs.`,
      },
      {
        type: `paragraph`,
        text: `Picture a healthcare revolution where hospitals and clinics are as streamlined as a Formula 1 pit crew. Doctors don’t just have your medical history; they have real-time updates, right at their fingertips. Redundant tests? Those are yesterday’s news. Now, envision transitional care that’s as smooth as your favorite playlist, virtual wards that bring the hospital to your home, and proactive care that doesn’t just treat you but keeps you one step ahead of illness.`,
      },
      {
        type: `paragraph`,
        text: `This isn’t some far-off utopia; it’s a reimagined, unified healthcare ecosystem. We’re talking about transitional care that bridges gaps, virtual wards that extend the hospital’s reach, and proactive care that anticipates your needs before you do. So, are you ready to hop on this groundbreaking journey? Because this isn’t science fiction — it’s the future of healthcare, and it’s unfolding right now.`,
      },

      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*PFr7-5uhEdoY1Wmb8uqByQ.jpeg`,
        alt: `We’ve been on the lookout for the perfect assistive technology to meet your unique healthcare needs. With the advancements in AI, we’ve not only trained but also deployed a system that’s completely ethical, incorruptible, multitasking, and eliminates the risk of human error.`,
      },

      {
        type: `heading`,
        text: `Healthcare Consumer-Centricity: The Core Philosophy`,
      },
      {
        type: `paragraph`,
        text: `“Dr. Vandana Shiva once said, ‘Healthcare consumer-first should not be a strategy; it should be a culture.’ And she couldn’t be more correct. According to a Deloitte survey, a staggering 80% of healthcare consumers are dissatisfied with their experience. Now, imagine a unified healthcare ecosystem that treats you not just as a healthcare consumer but as the VIP guest of honor. Picture Sarah, a busy working mom with hypertension.`,
      },
      {
        type: `paragraph`,
        text: `In our ecosystem, her primary care physician, cardiologist, and nutritionist are all in sync. Her medication is auto-refilled, her blood pressure is monitored in real-time, and she even receives proactive alerts for lifestyle adjustments. This isn’t just healthcare; it’s like having a personal healthcare butler who knows your needs, preferences, and values. This is the future we’re building — a future where the healthcare consumer is at the epicenter of a personalized, efficient, and compassionate healthcare system.`,
      },
      {
        type: `heading`,
        text: `Financial Implications: An Investment in the Future`,
      },
      {
        type: `paragraph`,
        text: `Let’s address the elephant in the room, shall we? According to the World Bank, India spends only about 1.28% of its GDP on healthcare. Building this healthcare paradise requires investment. But this shouldn’t be seen as a cost, but rather, an investment in the future. The benefits — improved patient outcomes, reduced healthcare costs, and a healthier population — far outweigh the costs.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*y41gVpTBgXUT2kN-sHtC5w.jpeg`,
        alt: `Formis Health Technologies`,
      },
      {
        type: `paragraph`,
        text: `We’re rolling out the Advantage cover, a healthcare game-changer that’s got your back — both now and in the future. It’s like the Zero Depreciation Vehicle Insurance of healthcare, but with a twist. It evolves with you and your needs, all on a Zero Cost Subscription Platform. So, go ahead and put that piggy bank back on the shelf; with Formis Health Technologies, you won’t have to smash it open for hospital bills! Invest in your present, and let us worry about your future`,
      },
      {
        type: `heading`,
        text: `The Road Ahead: A Collective Journey`,
      },
      {
        type: `paragraph`,
        text: `“As we look ahead into the next century, leaders will be those who empower others,” says Bill Gates. Creating a unified healthcare ecosystem is a collective journey that requires the collaboration of healthcare providers, policymakers, tech innovators, and patients. But with each step forward, we move closer to a healthcare system that truly serves the needs of all.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*nkVvHlGKk4EToquqnaUH_A.jpeg`,
        alt: `Formis Health Technologies`,
      },
      {
        type: `paragraph`,
        text: `Introducing a healthcare model that’s as consumer-centric as a barista who knows just how you like your coffee. It won’t prescribe anything until it knows you inside and out. And once it does, it becomes your personal fitness coach, keeping you in tip-top shape. All of this is fueled by the four pillars of Formis: the Network, the Community, the Care Team, and the Advantage. So, say goodbye to one-size-fits-all healthcare; we’re tailoring healthcare just for you!`,
      },
      {
        type: `heading`,
        text: `Real-Life Journeys: If Only…`,
      },
      {
        type: `paragraph`,
        text: `Imagine Priya, a diabetic patient, who usually ping-pongs between multiple specialists. In a unified system, her primary care physician, Dr. Mehta, endocrinologist Dr. Kapoor, and dietician Mrs. Joshi would be in perfect harmony. Her insulin recommended medicines would be auto-refilled, and her blood sugar levels monitored in real-time. No more midnight dashes to the ER; just timely interventions.`,
      },
      {
        type: `paragraph`,
        text: `Or consider Raj, a cancer survivor, who currently spends more time chasing medical records than enjoying his family. In our envisioned ecosystem, Raj’s oncologist, Dr. Verma, would have instant access to his entire medical history, allowing for more quality time discussing life, not just survival.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*EEo8iet1I8FEHT60rZojFQ.jpeg`,
        alt: `Formis Health Technologies`,
      },
      {
        type: `paragraph`,
        text: `Imagine a healthcare ecosystem where doctors collaborate, recommended medicines auto-refill, and medical records are just a click away. No more midnight ER dashes or chasing paperwork — just timely care and quality conversations. That’s the Formis vision.`,
      },
      {
        type: `heading`,
        text: `Conclusion: The Paradigm Shift`,
      },
      {
        type: `paragraph`,
        text: `We’re not discussing a mere upgrade or a cosmetic facelift; we’re talking about a tectonic shift in the healthcare landscape. Imagine transitioning from a fragmented, inefficient system — where healthcare consumers are often lost in a maze of medical jargon and paperwork — to a unified, consumer-centric ecosystem. Picture a world where your healthcare journey is as seamless as a well-choreographed ballet, where every healthcare provider is in sync, and your well-being is the star of the show.`,
      },
      {
        type: `paragraph`,
        text: `In this new paradigm, it’s not just about treating illnesses; it’s about anticipating them. It’s about a healthcare experience that’s so personalized, it feels like you have a medical team dedicated solely to you. We’re talking real-time monitoring, proactive care, and even virtual wards that bring the hospital experience right to your living room.`,
      },
      {
        type: `paragraph`,
        text: `The future of healthcare isn’t fragmented; it’s unified. It’s not reactive; it’s proactive. It’s not impersonal; it’s tailored to each individual healthcare consumer. And the time to embrace this groundbreaking future is not tomorrow, not ‘someday,’ but right now.`,
      },
      {
        type: `paragraph`,
        text: `So hop on board this healthcare revolution. It’s not just a wish; it’s a necessity. And trust us, it’s a ride worth taking.`,
      },
    ],
  },

  // Blog 6
  {
    id: "healing-broken-system",
    img: img6,
    title:
      "Healing a Broken System: A Founder’s Personal Journey to Transform Healthcare and Restore Human Dignity",
    desc: "India, despite its rich culture, faces a significant healthcare issue, mainly impacting the middle class. It’s not a minor problem but a major concern that affects daily life.",
    content: [
      {
        type: `paragraph`,
        text: `India, despite its rich culture, faces a significant healthcare issue, mainly impacting the middle class. It’s not a minor problem but a major concern that affects daily life.`,
      },
      {
        type: `paragraph`,
        text: `Consider a typical Indian family. Life is normal until someone falls ill. It’s a frightening event no one wants to experience.`,
      },
      {
        type: `paragraph`,
        text: `However, the illness isn’t the only problem. The complex and bureaucratic healthcare system, with long queues, confusing insurance processes, and indifferent professional services, is the major obstacle to recovery. This is a harsh reality many Indian families face. It’s a widespread crisis often overlooked.`,
      },
      {
        type: `paragraph`,
        text: `This article aims to highlight this problem. We’ll delve into the heart of the issue and spur discussions for possible solutions. This urgent issue requires recognition and collective action. We have to decide — will we remain bystanders or be part of the solution?`,
      },
      {
        type: `heading`,
        text: `The Unseen Crisis in Indian Families: A Tale That Could Be Yours`,
      },
      {
        type: `paragraph`,
        text: `In the bustling cities of India, where skyscrapers touch the heavens and flyovers crisscross like veins of a living organism, lies the epitome of Indian dreams — the family. Parents, the unyielding pillars, and children, the buds of dreams yet to bloom. But what if this dream, this sanctuary of love and hope, were to crumble? Not by the hands of fate, but by a healthcare system that fails us when we are most vulnerable.`,
      },
      {
        type: `quote`,
        text: `“Health is a human right, not a luxury.” — World Health Organization`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*5Iunjy7f2y-xmtxcN_jywg.png`,
        alt: `Health is a human right.`,
      },
      {
        type: `heading`,
        text: `The Maze of Medical Mayhem: When Healthcare Becomes a Living Nightmare`,
      },
      {
        type: `paragraph`,
        text: `Enter Mr. Ramakant, a middle-class father who embodies the aspirations of millions of Indians. He finds himself entangled in a web of medical chaos. The journey from one doctor to another becomes an ordeal of Herculean proportions. Picture this: a labyrinth of paperwork that makes no sense, waiting rooms filled with the scent of despair, and a clock that ticks not in seconds but in mounting bills. The family’s life, once a tapestry of joyous moments, now resembles a battlefield, where the enemy is not seen but deeply felt — a healthcare system that seems almost sadistically complex.`,
      },
      {
        type: `italic paragraph`,
        text: `Fact: According to a study by the Public Health Foundation of India, nearly 63 million Indians face poverty due to healthcare costs every year.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*82RG1HsLlqQRllZY-rAXnA.jpeg`,
        alt: `Medical Expenses, a Financial Toll.`,
      },
      {
        type: `heading`,
        text: `The Unbearable Weight of Medical Expenses: An Emotional and Financial Toll on the Indian Middle Class`,
      },
      {
        type: `paragraph`,
        text: `Mr. Ramakant, with a heart heavy as lead, watches as medical bills pile up to an astronomical Rs. 1,19,200. This isn’t just a number; it’s a gaping wound in the fabric of his life, consuming a staggering 20% of his annual income. Each bill is not just a piece of paper; it’s a tear in the eyes of his wife, a question mark on his children’s future, and a dark cloud over his own existence.`,
      },
      {
        type: `quote`,
        text: `“The greatest wealth is health.” — Virgil`,
      },
      {
        type: `heading`,
        text: `The Illusion of Safety: When Health Insurance Becomes a Mirage`,
      },
      {
        type: `paragraph`,
        text: `Despite having health insurance, Mr. Ramakant faced the ultimate betrayal. His claim was declined due to a lack of historical evidence and medical history. It was as if the safety net he thought he had was pulled from under him, leaving him free-falling into an abyss of despair and financial ruin.`,
      },
      {
        type: `italic paragraph`,
        text: `Stat: According to the Insurance Regulatory and Development Authority of India (IRDAI), nearly 80% of health insurance claims are disputed, primarily due to non-disclosure and lack of evidence.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*N7vJ26qsoJSE80PTizbLmQ.jpeg`,
        alt: `Health Insurance Becomes a Mirage`,
      },
      {
        type: `heading`,
        text: `The Moment of Truth: A Diagnosis That Shattered All Illusions`,
      },
      {
        type: `paragraph`,
        text: `When Mr. Ramakant finally consulted a specialist, urged by the desperate pleas of his loved ones and constrained by his dwindling finances, the news was devastating. A diagnosis of Stage 2 Colorectal Cancer emerged, like a dark cloud blotting out the sun. The proposed treatment cost? A staggering Rs. 20 Lakhs. It was not just another financial figure; it was a death sentence written in numbers.`,
      },
      {
        type: `quote`,
        text: `“Cancer is a word, not a sentence.” — John Diamond, but in India, it often becomes a life sentence due to inadequate healthcare.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Wt4PAvxVFeDpUHRSBC_Mxw.jpeg`,
        alt: `It Is About Time`,
      },
      {
        type: `heading`,
        text: `The Fateful Night: When Seconds Become Lifetimes, and Help Is Eons Away`,
      },
      {
        type: `paragraph`,
        text: `On a night that seemed like any other, tragedy struck. In those critical moments when every second was a precious droplet in the ocean of life, Mr. Ramakant found himself at the mercy of junior doctors — hesitant, waiting for their seniors, as if caught in a time warp. The golden hour for early intervention slipped through their fingers like sand, and with it, vanished the flicker of hope that could have saved a life.`,
      },
      {
        type: `quote`,
        text: `Fact: According to the Indian Journal of Critical Care Medicine, the “Golden Hour” is missed in over 90% of emergency cases in India.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*Hbv3BBUYcKY3O1ZuboDOZA.jpeg`,
        alt: `Emergency`,
      },
      {
        type: `heading`,
        text: `The Final Chapter: A Life Lost to a Symphony of Systemic Failures`,
      },
      {
        type: `paragraph`,
        text: `Despite the walls closing in on him, Mr. Ramakant continued his treatment at a government hospital. Bound by the shackles of financial constraints and the absence of health insurance, he was left at the mercy of a system that had already failed him multiple times. The lack of timely diagnosis and subpar treatment were not mere oversights; they were the final nails in the coffin of his life.`,
      },
      {
        type: `quote`,
        text: `“The tragedy of life is not death, but what we let die inside of us while we live.” — Norman Cousins`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*uxW2roPAhHXbx5WbNFtEzQ.jpeg`,
        alt: `Your Life Matters`,
      },
      {
        type: `heading`,
        text: `The Genesis of Formis: A Revolution Born from the Ashes of Pain`,
      },
      {
        type: `paragraph`,
        text: `This narrative is not a work of fiction. It’s a chapter ripped from the life of one of Formis’ founders. It’s this raw, gut-wrenching experience that fuels our unyielding commitment to change. We, at Formis, carry this emotional weight as a constant reminder of the revolution that must come. We seek to create a proactive healthcare system to avoid future tragedies. We carry forward the pain of past losses and the aspiration for a future where no family suffers due to system failure.`,
      },
      {
        type: `subheading`,
        text: `The Future We Envision: Formis’ Unified Healthcare Ecosystem`,
      },
      {
        type: `paragraph`,
        text: `At Formis, we aim to transform Indian homes. “Our motivation originates from personal experiences of many who struggle with fragmented healthcare and uninvolved health methods.”We experienced a profound loss of a family member Mr. Ramakant due to such a flawed system, highlighting the dire consequences of inefficient healthcare. His passing was influenced more by inadequate hospital services, delayed diagnosis and high healthcare costs than his actual illness. This incident emphasised the destructive effects of negligent healthcare that affects numerous families. Thus, our mission — to prevent similar suffering faced by Ramakant’s family — gains added importance.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*YjbEiuqCYpwV6U0fB-9koQ.jpeg`,
        alt: `Always There, Never Square whether on the move or not.`,
      },
      {
        type: `heading`,
        text: `Your Role in This Revolution: The Time for Spectatorship Is Over`,
      },
      {
        type: `paragraph`,
        text: `Look, we at Formis aren't just sitting around sipping chai and watching cricket. We're on the field, rewriting the rulebook of Indian healthcare. And you, dear reader, are not just a spectator; you're a key player in this game-changing inning.`,
      },
    ],
  },

  // Blog 7
  {
    id: "what-is-health-score",
    img: img7,
    title: "What is Health Score? It’s History, Importance and Relevance.",
    desc: "Introduction to Health Score",
    content: [
      {
        type: `heading`,
        text: `Introduction to Health Score`,
      },
      {
        type: `paragraph`,
        text: `A Health Score is a comprehensive numerical representation of an individual’s overall health status. It encapsulates various dimensions of health, including physical, mental, emotional, and even social well-being. The concept of a Health Score is not merely a modern invention but has roots in the historical evolution of healthcare assessment and personalized medicine.`,
      },
      {
        type: `italic paragraph`,
        text: `A Health Score is like the credit score of your well-being, but instead of judging your shopping habits, it’s sizing up everything from your bicep curls to your emotional ups and downs. Think of it as a numerical selfie of your physical, mental, emotional, and even social health. It’s like a report card, but instead of grades, you get a number that tells you how fabulous you’re doing (or not). And just like your credit score, it’s not something cooked up last Tuesday. It’s rooted in the grand tradition of healthcare, only now with a Formis twist. With us, your Health Score isn’t just a number; it’s a conversation starter, a high-five, and maybe even a reason to eat that salad instead of a burger. Welcome to the future of health, where numbers have never been so much fun!`,
      },
      {
        type: `heading`,
        text: `Historical Evolution`,
      },
      {
        type: `inline paragraph`,
        text: `The idea of quantifying health has been a part of medical practice for centuries. From the ancient practice of pulse diagnosis to modern biometric screenings, the pursuit of understanding health through measurable means has been a constant endeavor. `,
      },
      {
        type: `inline bold`,
        text: `The Health Score, as we know it today, is a culmination of these efforts, integrating diverse data points into a single, actionable metric.`,
      },
      {
        type: `paragraph`,
        text: `In a world where healthcare is becoming increasingly complex and insightful, the Health Score serves as a universal tool to navigate individual health needs and turn insights into actions. It transcends geographical, cultural, and socioeconomic boundaries, providing a common language for healthcare providers, patients, policymakers, and even insurers.`,
      },
      {
        type: `heading`,
        text: `The Health Crisis in India: A Statistical Overview`,
      },
      {
        type: `subheading`,
        text: `Prevalence of Chronic Conditions`,
      },
      {
        type: `paragraph`,
        text: `India’s healthcare landscape is marked by a growing prevalence of chronic conditions. According to The Lancet Diabetes and Endocrinology Journal, the prevalence of diabetes in India has reached 11.4 percent, while 35.5 percent and 15.3 percent of people are suffering from hypertension and pre-diabetes, respectively.`,
      },
      {
        type: `paragraph`,
        text: `A study by the Madras Diabetic Research Foundation in collaboration with the Indian Council of Medical Research (ICMR) found that generalized obesity and abdominal obesity in India stood at 28.6 percent and 39.5 percent, respectively.`,
      },
      {
        type: `paragraph`,
        text: `At present, 101 million people in India are living with diabetes whilst 136 million are suffering from pre-diabetes and another 315 million people have high blood pressure. Additionally, 213 million have hypercholesterolemia.`,
      },
      {
        type: `subheading`,
        text: `Impact on Public Health`,
      },
      {
        type: `paragraph`,
        text: `The economic toll of chronic conditions in India is staggering. The direct medical costs, loss of productivity, and long-term care requirements present a substantial financial challenge. Investments in preventive care, early intervention, and personalized treatment are not just medically sound but economically prudent.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*7Q3xyMi9RBV-BrjIW5Q8fg.jpeg`,
        alt: `Prevent`,
      },
      {
        type: `heading`,
        text: `Components of Health Score`,
      },
      {
        type: `subheading`,
        text: `Medical Assessments`,
      },
      {
        type: `paragraph`,
        text: `The foundation of a Health Score lies in accurate and comprehensive medical assessments. These include routine screenings, laboratory tests, imaging studies, and specialized evaluations. The integration of these assessments provides a detailed insight into an individual’s physiological health.`,
      },
      {
        type: `subheading`,
        text: `Lifestyle Factors`,
      },
      {
        type: `paragraph`,
        text: `A Health Score recognizes that health is not merely the absence of disease but a state of complete well-being. Lifestyle factors such as diet, exercise, sleep, stress management, and social connections play a vital role in shaping overall health. The inclusion of these factors in the Health Score reflects a holistic approach to health and wellness.`,
      },
      {
        type: `subheading`,
        text: `Psychological Well-being`,
      },
      {
        type: `paragraph`,
        text: `Mental and emotional health are integral to the overall Health Score. Assessments of mood, stress levels, coping mechanisms, and even happiness contribute to a nuanced understanding of health. The recognition of mental health within the Health Score framework underscores the interconnectedness of mind and body.`,
      },
      {
        type: `subheading`,
        text: `Environmental Influences`,
      },
      {
        type: `paragraph`,
        text: `The environment in which we live, work, and play has a profound impact on health. Factors such as air quality, access to green spaces, occupational hazards, and community support are considered in the calculation of a Health Score. This environmental lens adds a layer of complexity and relevance to the Health Score, aligning it with the broader determinants of health.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*9q59-M4OPRo1pNjPgWqzNA.jpeg`,
        alt: `Medical, lifestyle, mental, and environmental health factors.`,
      },
      {
        type: `heading`,
        text: `Methodologies for Calculating Health Score`,
      },
      {
        type: `subheading`,
        text: `Algorithms and Models`,
      },
      {
        type: `paragraph`,
        text: `The calculation of a Health Score involves sophisticated algorithms and models that integrate diverse data points. These algorithms consider the interplay between various health factors, assigning weights and scores based on scientific evidence and clinical expertise.`,
      },
      {
        type: `subheading`,
        text: `Standardized Tools (SF-36, SF-12, PHQ-9, etc.)`,
      },
      {
        type: `paragraph`,
        text: `Several standardized tools are utilized in the calculation of Health Scores. Instruments like the Short Form Health Survey (SF-36 and SF-12) and the Patient Health Questionnaire (PHQ-9) provide validated measures of health domains such as physical functioning, mental health, and quality of life.`,
      },
      {
        type: `subheading`,
        text: `Challenges and Limitations`,
      },
      {
        type: `paragraph`,
        text: `While the concept of a Health Score is promising, it is not without challenges. The accuracy and reliability of the score depend on the quality of data, the robustness of the algorithms, and the contextual relevance of the measures. Ethical considerations, data privacy, and potential biases must also be addressed to ensure the responsible use of Health Scores.`,
      },
      {
        type: `heading`,
        text: `Applications of Health Score`,
      },
      {
        type: `subheading`,
        text: `Personalized Care`,
      },
      {
        type: `paragraph`,
        text: `Health Scores enable healthcare providers to design personalized care plans. By understanding the unique health profile of each individual, providers can offer targeted interventions, therapies, and support.`,
      },
      {
        type: `subheading`,
        text: `Preventive Measures`,
      },
      {
        type: `paragraph`,
        text: `Health Scores facilitate preventive care by identifying potential health risks and vulnerabilities. Timely interventions, lifestyle modifications, and preventive screenings can be implemented to avert the progression of chronic conditions.`,
      },
      {
        type: `subheading`,
        text: `Continuous Monitoring`,
      },
      {
        type: `paragraph`,
        text: `The dynamic nature of Health Scores allows for continuous monitoring and management of health. Regular updates to the score reflect changes in health status, enabling ongoing adjustments to care plans and fostering a continuous care relationship.`,
      },
      {
        type: `subheading`,
        text: `Public Health Planning`,
      },
      {
        type: `paragraph`,
        text: `On a broader scale, Health Scores can inform public health policies and strategies. Aggregated data on population health scores can guide the allocation of resources, the design of health programs, and the evaluation of public health interventions.`,
      },
      {
        type: `subheading`,
        text: `Insurance and Corporate Wellness`,
      },
      {
        type: `paragraph`,
        text: `Beyond clinical care, Health Scores are being utilized in insurance underwriting and corporate wellness programs. They provide a quantifiable measure of health risk, informing pricing strategies, incentives, and wellness initiatives.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*vPVcVQjG1du7ICk7NJr9Cw.jpeg`,
        alt: `Public Health Planning`,
      },

      {
        type: `heading`,
        text: `Case Studies and Success Stories`,
      },
      {
        type: `subheading`,
        text: `Implementation in Healthcare Systems`,
      },
      {
        type: `paragraph`,
        text: `Several healthcare systems globally have successfully implemented Health Scores as part of their patient care strategy. These case studies demonstrate improved patient engagement, enhanced clinical outcomes, and cost savings.`,
      },
      {
        type: `subheading`,
        text: `Impact on Individual Health Outcomes`,
      },
      {
        type: `paragraph`,
        text: `Personal anecdotes and success stories highlight the transformative impact of Health Scores on individual lives. From early detection of life-threatening conditions to the empowerment of healthy lifestyle choices, Health Scores have proven to be a catalyst for positive health change.`,
      },
      {
        type: `subheading`,
        text: `Innovations and Breakthroughs`,
      },
      {
        type: `paragraph`,
        text: `The field of Health Scores is marked by continuous innovation and technological advancements. Breakthroughs in artificial intelligence, machine learning, and data analytics are revolutionizing the way Health Scores are calculated, applied, and interpreted.`,
      },

      {
        type: `heading`,
        text: `The Future of Health Score`,
      },
      {
        type: `subheading`,
        text: `Technological Advancements (AI, Machine Learning, etc.)`,
      },
      {
        type: `paragraph`,
        text: `The integration of artificial intelligence, machine learning, and other advanced technologies is shaping the future of Health Scores. These technologies enable more precise, predictive, and personalized Health Scores, opening new horizons in healthcare innovation.`,
      },
      {
        type: `subheading`,
        text: `Integration with Wearables and IoT`,
      },
      {
        type: `paragraph`,
        text: `The Internet of Things (IoT) and wearable devices offer exciting possibilities for real-time monitoring and updating of Health Scores. From fitness trackers to smart medical devices, the continuous flow of health data enriches the Health Score, making it a living, breathing reflection of an individual’s health.`,
      },
      {
        type: `subheading`,
        text: `Policy and Regulation`,
      },
      {
        type: `paragraph`,
        text: `As Health Scores gain prominence, there will be a need for clear policies and regulations governing their use, standardization, and ethics. Collaborative efforts between governments, healthcare organizations, technology companies, and patient advocates will be crucial in shaping responsible and effective Health Score practices.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*TVtMDcAngHvbCdjWybASOw.jpeg`,
        alt: `Future of health score`,
      },
      {
        type: `heading`,
        text: `Comparison with Global Practices`,
      },
      {
        type: `subheading`,
        text: `Health Score Models in Other Countries`,
      },
      {
        type: `paragraph`,
        text: `Different countries have adopted various models and methodologies for Health Scores. A comparative analysis of these practices provides valuable insights into the effectiveness, challenges, and opportunities of Health Scores in diverse healthcare contexts.`,
      },
      {
        type: `subheading`,
        text: `Lessons and Insights`,
      },
      {
        type: `paragraph`,
        text: `Learning from global practices enriches the understanding and application of Health Scores. The successes and failures of Health Score implementations across the world offer lessons that can guide the continuous improvement and adaptation of Health Scores to local needs and cultures.`,
      },
      {
        type: `subheading`,
        text: `Collaboration and Standardization`,
      },
      {
        type: `paragraph`,
        text: `International collaboration and standardization efforts can enhance the credibility, usability, and impact of Health Scores. Shared standards, benchmarks, and research contribute to a global community of practice around Health Scores.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*tv4lYJqApzIOQjfLTwnYCg.jpeg`,
        alt: `A healthier future!`,
      },
      {
        type: `heading`,
        text: `Conclusion: A Vision for a Healthier Future`,
      },
      {
        type: `paragraph`,
        text: `The Health Score at Formis isn’t just a number; it’s the healthcare world’s equivalent of a five-star gourmet meal, carefully crafted and seasoned with personalized, preventive, continuous, and compassionate care. It’s the Sherlock Holmes of healthcare, solving the mysteries of well-being with the finesse of a master detective.`,
      },
      {
        type: `paragraph`,
        text: `In the bustling bazaar of India’s health challenges, the Health Score is the guiding star, the compass leading us not just to hope but to a new frontier of innovation. It’s the ultimate multitool, weaving together medical assessments, lifestyle quirks, emotional landscapes, and even the weather outside into a single, actionable, holistic score. It’s not just healthcare; it’s healthcare with style!`,
      },
      {
        type: `paragraph`,
        text: `At Formis, we don’t just use the Health Score; we celebrate it like the main event at a grand festival. It’s the headliner, the showstopper, the fireworks finale in personalized care, preventive measures, continuous monitoring, and public health planning. It’s the future, and it’s wearing sunglasses.`,
      },
      {
        type: `paragraph`,
        text: `So, as we stand on the brink of a healthcare revolution, the Health Score beckons us to a journey not just of healing but of discovery, adventure, and empowerment. It’s the open road, the wind in your hair, the song in your heart.`,
      },
      {
        type: `paragraph`,
        text: `In the pursuit of a healthier future, the Health Score at Formis is more than a measure; it’s a movement. It’s the jazz in healthcare’s symphony, the sizzle in its steak, the twinkle in its eye.`,
      },
    ],
  },

  // Blog 8
  {
    id: "continuous-care-guide",
    img: img8,
    title:
      "What is Continuous Care aka Continuum of Care? A Not-So-Boring Guide",
    desc: "Oh, continuous care, the healthcare equivalent of a never-ending Netflix binge, but with fewer cliffhangers and more stethoscopes. Let’s dive into this fascinating world without the medical jargon that makes you want to hit the snooze button.",
    content: [
      {
        type: "paragraph",
        text: "Oh, continuous care, the healthcare equivalent of a never-ending Netflix binge, but with fewer cliffhangers and more stethoscopes. Let’s dive into this fascinating world without the medical jargon that makes you want to hit the snooze button.",
      },
      {
        type: "paragraph",
        text: "Continuous Care Delivery is like being the ultimate plant parent, nourishing your green buddy from its tiny seedling days all the way to its grand, leafy adulthood. And even when it’s on the verge of becoming compost, you’re still there with the water and sunshine, ensuring it blossoms every single day. That’s dedication! Well, Formis brings you that same level of commitment, but for your health. Imagine us as your healthcare gardeners, always there with the right care, at the right time, so you can bloom in all your glory. Now, who wouldn’t want that?",
      },
      {
        type: "heading",
        text: "Continuous Care: The What and the Why",
      },
      {
        type: "paragraph",
        text: "Continuous care is like having a healthcare bestie who’s always there for you, no matter what. They’re the one who knows all your medical history, and they’re always up for a chat about your latest health concerns. They’re also the one who can help you navigate the healthcare system, and make sure you get the care you need.",
      },
      {
        type: "paragraph",
        text: "So if you’re looking for a healthcare confidante, continuous care is the way to go. It’s like having a healthcare marathon partner, who will help you stay on track and reach your health goals",
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:2000/format:webp/1*i24VU7gkCGCjsapU4fisTA.png`,
        alt: `Continuous Care`,
      },
      {
        type: `heading`,
        text: `The Benefits of Continuous Care (Or Why It’s Cooler Than Your Average Checkup)`,
      },
      {
        type: `inline subheading`,
        text: `1. No More Playing Healthcare Roulette:`,
      },
      {
        type: `inline paragraph`,
        text: `With continuous care, you’re not bouncing between doctors who have no idea what the other one said. It’s like having a group chat for your health, but without the annoying notifications. Without continuous care, you might feel like a pinball, bouncing between doctors who don’t know your medical history.`,
      },
      {
        type: `inline bold`,
        text: `Imagine going to one doctor for a sugar fix (diabetes) and another for a knee jiggle (knee pain), and the second one hands you a candy bar as recommended medicines. Oops! Wrong move, doc!`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `2. Personalized Care (Yes, They Actually Know Your Name):`,
      },
      {
        type: `inline paragraph`,
        text: `Continuous care means your healthcare team knows you, understands you, and probably even remembers your dog’s name. It’s healthcare with a personal touch. They’ll tailor your care like a bespoke suit. Got a chronic condition? They’ll whip up a management plan that fits you like those jeans you’ve been eyeing.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `3. Prevention Over Cure (Because Who Likes Surprises?):`,
      },
      {
        type: `inline paragraph`,
        text: `Continuous care focuses on keeping you healthy rather than just treating you when you’re sick. It’s like having a weather forecast for your health.They’ll nag you about regular checkups, eating your veggies, and hitting the gym. But hey, at least they care enough to keep you from turning into a couch potato.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `4. Cost-Effective (Because Who Doesn’t Love Saving Money?):`,
      },
      {
        type: `inline paragraph`,
        text: `Continuous care often leads to early detection and prevention, which can save you money in the long run. It’s like catching a sale every time you shop for your health. Continuous care saves you money, like a Black Friday sale but for your health.`,
      },
      {
        type: `bold italic`,
        text: `Manage that chronic condition, and you’ll avoid hospital visits as often as you avoid your in-laws.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `5. A Seamless Experience (No More Deja Vu):`,
      },
      {
        type: `inline paragraph`,
        text: `Tired of repeating your medical history like a broken record? With continuous care, your doctors are all in sync, like a well-rehearsed boy band. No more repeating yourself; they’ve got the beat.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `6. Peace of mind:`,
      },
      {
        type: `inline paragraph`,
        text: `Imagine a team of healthcare providers holding hands around you, singing Kumbaya (secret to stress fatigue release revealed). That’s the peace of mind continuous care brings. They’re all there, working in harmony to keep you healthy, like a wellness choir.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `7. Improved quality of life:`,
      },
      {
        type: `inline paragraph`,
        text: `Continuous care can help you to manage your health conditions, stay active, and live independently. Got a chronic condition? Your continuous care team will help you live your best life, like a life coach with a stethoscope. They’ll develop a plan that lets you live normally, or as normal as anyone can be these days.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `8. Reduced risk of hospitalization:`,
      },
      {
        type: `inline paragraph`,
        text: `Continuous care can help to reduce the risk of hospitalization by providing preventive care and managing chronic conditions. It is like having a healthcare bodyguard. They’ll make sure you’re taking your meds and getting checkups, so you don’t end up in the hospital. It’s like having a bouncer for your health, keeping the bad stuff out.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },
      {
        type: "bold italic",
        text: "So, there you have it, continuous care in a nutshell, or a medical chart, or whatever metaphor works for you. It’s like having a healthcare BFF who’s always there for you, even when you eat that extra slice of cake. With Formis, we’re all about that continuous care life, and we do it with a wink and a smile. Because healthcare doesn’t have to be a drag, right?"
      },
      {
        type: "heading",
        text: "The Disadvantages of Not Having Continuous Care (Or Why You Should Care)"
      },
      {
        type: `inline subheading`,
        text: `1. The Healthcare Maze:`,
      },
      {
        type: `inline paragraph`,
        text: `Without continuous care, navigating healthcare is like trying to solve a Rubik’s Cube blindfolded while riding a unicycle on a tightrope. Good luck with that, and may the odds be ever in your favor.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `2. One-Size-Fits-All (And It Never Fits Right):`,
      },
      {
        type: `inline paragraph`,
        text: `Without continuous care, you might end up with generic care that fits like those one-size-fits-all socks you got as a gag gift. Spoiler alert: They never fit right, and neither does one-size-fits-all healthcare.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `3. Healthcare Whack-a-Mole (It’s Less Fun Than It Sounds):`,
      },
      {
        type: `inline paragraph`,
        text: `Without continuous care, treating one issue might lead to another popping up unexpectedly, like a never-ending game of whack-a-mole with your health. Spoiler alert: There’s no prize at the end of this game.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `4. Lost in Translation (Literally):`,
      },
      {
        type: `inline paragraph`,
        text: `Without continuous care, your medical information might get lost between providers, like playing a game of telephone with your health. Last time we checked, “allergic to penicillin” shouldn’t turn into “allergic to penguins.”`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `5. Lack of healthcare consumer control:`,
      },
      {
        type: `inline paragraph`,
        text: `When you do not have continuous care, you may have to see different providers for different needs. This can make it difficult to coordinate your care, and it can also be frustrating if you feel like you are not in control of your own healthcare. For example, if you are seeing a doctor for your diabetes and a therapist for your anxiety, it can be difficult to make sure that both providers are aware of your treatment plan and that they are working together to help you.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `6. Increased risk of errors:`,
      },
      {
        type: `inline paragraph`,
        text: `When care is not coordinated, there is an increased risk of errors. This is because different providers may not have access to all of your medical information, and they may make decisions based on incomplete information. For example, if you are taking a medication for your heart condition, and you see a different doctor for your knee pain, the second doctor may not be aware of the first medication, and they may prescribe a medication that interacts with it. This could lead to a serious medical emergency.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `7. Increased costs:`,
      },
      {
        type: `inline paragraph`,
        text: `Continuous care can be more expensive than traditional healthcare. This is because it requires more coordination and communication between providers. It is like ordering the fancy coffee with all the extras. Seeing a doctor, therapist, and nutritionist? Each one bills separately, and suddenly, your healthcare bill looks like a five-star restaurant check.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `bold italic`,
        text: `The Formis Solution: Tired of the healthcare circus? Enter Formis, where continuous care is served with a side of sanity and a sprinkle of style. We’re like the personal stylist of healthcare, making sure everything fits just right. No more mazes, no more games, just personalized care that actually works for you. And, guess what nobody bills you separately ever after Formis translating into an essentially zero cost access to healthcare. — Learn More today at www.heyformis.com.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:2000/format:webp/1*k1YCdePzLtWxiUlo6-VTAw.png`,
        alt: `Healthcare Needs`
      },
      {
        type: `heading`,
        text: `A Tailored Approach for Different Healthcare Needs — From Healthy High-Fives to Chronic Crusades.`,
      },
      
      {
        type: "ul item",
        listHeading: "For the Healthy Ones:",
        text: "Continuous care isn’t just for the sick and weary; it’s for the fit and fabulous too! Think of it as your health’s personal trainer or a life-coach, keeping you in tip-top shape. Regular checkups, preventive measures, and maybe even a high-five when you eat a salad instead of a burger. It’s like having a cheerleader for your well-being.",
      },
      {
        type: "ul item",
        listHeading: "For the Acute Adventurers:",
        text: "Got a sudden illness or injury? Continuous care is like the superhero swooping in to save the day. No need to navigate the healthcare maze; your continuous care team is there with a map, a compass, and a reassuring pat on the back. They’ll guide you through the wilderness of acute care with the grace of a gazelle and the wisdom of a healthcare owl.",
      },
      {
        type: "ul item",
        listHeading: "For the Chronic Crusaders:",
        text: "Living with a chronic condition? Continuous care is your sidekick, your buddy, your confidante, your healthcare BFF. They’ll tailor a plan that fits you like that favorite pair of jeans you refuse to throw away. They’ll help you manage your condition with the finesse of a master chef, whipping up a recipe for success. And they’ll do it all with a smile, a wink, and maybe even a joke or two.",
      },
      {
        type: "ul item",
        listHeading: "The Disadvantage of Going Solo:",
        text: "Without continuous care, you’re like a lost kitten in the healthcare wilderness. You might face the confusion of transitional care, stumbling around like trying to dance the tango with two left feet. Or you might end up with generic care that fits as awkwardly as a toddler in dad’s oversized shoes. Either way, it’s a bewildering and uncomfortable situation.",
      },

      {
        type: `break`
      },

      {
        type: "link",
        href:  `https://www.heyformis.com/`,
        text: "The Formis Twist: ",
      },
      {
        type: "paragraph",
        text: "Whether you’re thriving in health, facing an acute adventure, or navigating a chronic condition, just say “Hey! Formis.” We’re here, mixing a cocktail of care with a twist of innovation and a splash of excitement. We’re transforming continuous care into a celebration of you, our esteemed “Healthcare Consumer” (because “patient” is so passé, and we never assume you’re under the weather). With Formis, you’re not just part of the healthcare system; you’re the star of a healthcare fiesta that’s all about honoring your unique journey.",
      },

      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:1400/format:webp/1*e1wuzLG1y6ml0X7IPHqpRw.png`,
        alt: `Formis Healthcare Technologies`
      },

      {
        type: `heading`,
        text: `NHS Scotland: A Continuous Care Success Story (Or How to Do Healthcare Right)`,
      },
      {
        type: `bold`,
        text: `NHS Scotland has been rocking the continuous care game. They’ve managed to create a system where healthcare is not just a series of random encounters with people in white coats. It’s a well-coordinated dance, and everyone knows the steps.`,
      },
      {
        type: `bold italic`,
        text: `They’ve shown that continuous care can lead to better outcomes, happier patients, and probably fewer grey hairs for healthcare providers. It’s like the Beatles of healthcare, a classic hit that never gets old.`,
      },
      {
        type: `paragraph`,
        text: `Now, if you’re thinking, “This all sounds great, but where can I get some of this continuous care goodness?” Look no further and tap Hey! Formis today.`,
      },
      {
        type: `paragraph`,
        text: `We’re taking continuous care and adding a dash of fun, a sprinkle of technology, and a whole lot of understanding. With Hey Formis, continuous care is not just a concept; it’s a party, and you’re the guest of honor.`,
      },
      {
        type: `heading`,
        text: `Conclusion: Continuous Care, It’s Not Just for Healthcare Nerds`,
      },
      {
        type: `paragraph`,
        text: `Continuous care is more than a healthcare trend; it’s a must-have. It’s like the smartphone of healthcare, and once you’ve experienced it, you’ll wonder how you ever lived without it.`,
      },
      {
        type: `paragraph`,
        text: `So, if you’re tired of playing healthcare roulette and want a healthcare experience that’s personalized, coordinated, and downright fun, give continuous care a try. And if you want continuous care with a side of humor and a sprinkle of sarcasm, give Formis Health Technologies Private Limited a call.`,
      },
      {
        type: `bold italic`,
        text: `Because at Formis, we don’t just do healthcare; we do it right; by implementing the fundamental principle correctly that is “BASIC” coupled with Simple, Smart and Human Centered Care technology.`,
      },
    ],
  },

  // Blog 9
  {
    id: "disconnected-experiences-healthcare",
    img: img9,
    title:
      "Disconnected Experiences in Healthcare: A Critical Gap in Patient-Centric Care",
    desc: "In the age of technology and information, one would expect healthcare to be a seamless, integrated experience. Yet, for many patients, healthcare providers, and insurers, the reality is far from this ideal. Disconnected experiences in healthcare are not just inconvenient; they are a critical gap that can lead to inefficiencies, increased costs, and compromised patient outcomes.",
    content: [
      {
        type: `styled paragraph`,
        text: `In the age of technology and information, one would expect healthcare to be a seamless, integrated experience. Yet, for many patients, healthcare providers, and insurers, the reality is far from this ideal. Disconnected experiences in healthcare are not just inconvenient; they are a critical gap that can lead to inefficiencies, increased costs, and compromised patient outcomes.`
      },
      {
        type: `paragraph`,
        text: `According to a report by the Indian Journal of Medical Informatics, fragmented healthcare information systems in India lead to inefficiencies that account for an estimated loss of INR 20,000 crores annually. This fragmentation results in missed care opportunities, redundant tests, and potential patient harm.`
      },
      {
        type: `heading`,
        text: `The Problem of Disconnection`
      },
      {
        type: `paragraph`,
        text: `The traditional healthcare system often operates in silos. Patients navigate a maze of specialists, tests, and treatments, with little coordination between different parts of their care journey. Healthcare providers struggle with fragmented information, leading to duplication of efforts and potential errors. Insurers face challenges in fraud detection and personalized plan creation due to a lack of real-time insights.`
      },
      {
        type: `bold`,
        text: `These disconnected experiences manifest in various ways:`
      },

      {
        type: `inline subheading`,
        text: `1. Lack of Continuity in Patient Care:`,
      },
      {
        type: `inline paragraph`,
        text: `Patients often feel lost as they move between different healthcare providers, with no single entity responsible for their overall care. This lack of continuity can lead to missed diagnoses, delayed treatments, and increased stress for patients and their families.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `2. Inefficient Healthcare Delivery:`,
      },
      {
        type: `inline paragraph`,
        text: `Without a unified view of a patient’s medical history, healthcare providers may order unnecessary tests, overlook critical information, or struggle to coordinate care with other providers. This inefficiency not only drives up costs but can also compromise patient safety.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `3. Challenges for Insurers:`,
      },
      {
        type: `inline paragraph`,
        text: `The absence of interoperable systems makes it difficult for insurers to create personalized plans, detect fraud, and assess risks accurately. This lack of integration leads to higher costs and less value for both insurers and insured individuals.`,
      },

      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `styled paragraph`,
        text: `In the world of healthcare, different parts often operate in isolation, like isolated islands. Doctors, hospitals, pharmacies, and insurance companies each have their own systems, and they don’t always communicate well with each other. This lack of connection creates confusion, delays, and inefficiencies, making healthcare a complex maze for patients to navigate.`
      },
      {
        type: `quote`,
        text: `Center for Connected Medicine and The Health Management Academy found that 64% of healthcare executives believe that lack of interoperability is a major barrier to digital transformation in healthcare.`
      },
      {
        type: `paragraph`,
        text: `Disconnected experiences in healthcare are akin to navigating a city with disjointed roads, where each healthcare entity — doctors, hospitals, pharmacies, and insurance companies — operates in isolation, like islands in an archipelago. This lack of a unified, interconnected system creates a labyrinth of confusion, inefficiency, and delays, turning the patient’s journey into a complex, often frustrating, quest for care.`
      },
      {
        type: `bold`,
        text: `The repercussions of such a fragmented system are far-reaching:`
      },

      {
        type: `inline subheading`,
        text: `1. Compromised Care Delivery:`,
      },
      {
        type: `inline paragraph`,
        text: `The absence of a comprehensive, accurate view of a patient’s medical history can lead to misdiagnosis, delayed treatments, redundant tests, and even medical errors. It’s like trying to complete a puzzle with missing pieces.`,
      },
      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `2. Deteriorated Patient Experience:`,
      },
      {
        type: `inline paragraph`,
        text: `Patients are forced to navigate a maze of disjointed services, repeating their medical history to each new provider, coordinating their own care, and deciphering complex billing and insurance processes. This not only leads to dissatisfaction but also places undue stress on the patient, detracting from their focus on recovery.`,
      },
      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `3. Escalated Healthcare Costs:`,
      },
      {
        type: `inline paragraph`,
        text: `The inefficiencies bred by a lack of coordination inflate healthcare costs, leading to unnecessary hospital readmissions, avoidable complications, and wasteful spending on duplicate procedures.`,
      },
      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `4. Threat to Patient Safety:`,
      },
      {
        type: `inline paragraph`,
        text: `In the most severe scenarios, disconnected experiences can pose a direct threat to patient safety. Critical information falling through the cracks can lead to harmful drug interactions or allergic reactions, and in extreme cases, can be life-threatening.`,
      },
      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `inline subheading`,
        text: `5. Impediment to Public Health:`,
      },
      {
        type: `inline paragraph`,
        text: `On a macro level, a disjointed healthcare system can obstruct public health initiatives. For instance, during a pandemic, the absence of an integrated health information system can hamper tracking of disease spread and coordination of response efforts.`,
      },
      {
        type: `break`
      },
      {
        type: `break`
      },

      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:2000/format:webp/1*hHt8d8MU0_M3BGlm-LQ5Dg.png`,
        alt: `Disconnect in Healthcare Creates a Domino Effect. Inefficient, Costly and Potentially Dangerous Care.`
      },
      {
        type: `paragraph`,
        text: `In essence, disconnected experiences in healthcare create a domino effect, leading to inefficient, costly, and potentially dangerous care. The need of the hour is a solution that can bridge these gaps, creating a unified, patient-centric system that enhances patient outcomes, improves patient experiences, and optimizes the use of healthcare resources.`,
      },
      {
        type: `heading`,
        text: `A Personal Tragedy: A true evidence.`,
      },
      {
        type: `paragraph`,
        text: `The impact of disconnected experiences in healthcare is not just theoretical. It’s personal, painful, and sometimes tragic. A poignant example is the loss of a loved one due to a fall from a hospital bed, a seemingly minor incident that was ignored and led to devastating consequences.`,
      },
      {
        type: `paragraph`,
        text: `This personal tragedy unfolded when a loved one, already in a vulnerable state of health, experienced a fall in the hospital. Despite the presence of healthcare professionals and advanced medical equipment, this seemingly minor incident was overlooked. The fall, which could have been a critical signal of deteriorating health or an immediate medical concern, was not communicated effectively among the healthcare team.`,
      },
      {
        type: `paragraph`,
        text: `The absence of real-time monitoring further compounded the issue. In an ideal scenario, real-time monitoring systems would have immediately flagged the incident, prompting swift medical intervention. However, the disconnected systems failed to recognize the urgency of the situation.`,
      },
      {
        type: `paragraph`,
        text: `Moreover, the failure to recognize and respond to this critical incident in a timely manner resulted in a tragic loss. A life that could have been saved with prompt medical attention was lost due to gaps in communication and coordination.`,
      },
      {
        type: `paragraph`,
        text: `This personal tragedy serves as a stark reminder of the profound effects of disconnection in healthcare. It underscores the urgent need for an integrated, responsive healthcare system that prioritizes patient safety and well-being above all else. It is a call to action for all of us in the healthcare sector to strive for a system that truly communicates, connects, and cares.`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:2000/format:webp/1*oeTRIRXE-vpgoOBwPiWX_w.png`,
        alt: `In healthcare we are not just dealing with systems and processes. We are dealing with lives.`
      },
      {
        type: `styled paragraph`,
        text: `In the intricate dance of healthcare, every step, every move matters. A missed step, a delayed move can lead to a tragic tumble. Our mission is to ensure that no such missteps occur, that every move is coordinated, every step is secure. Because in healthcare, we’re not just dealing with systems and processes, we’re dealing with lives.`,
      },
      {
        type: `heading`,
        text: `Formis: Building Bridges not Walls, for a connected experience.`,
      },
      {
        type: `paragraph`,
        text: `Formis Health Technologies Private Limited is not just another player in the healthcare industry. We are a force of change, a bridge builder, connecting the isolated islands of the healthcare landscape into a cohesive, efficient, and patient-centric ecosystem.`,
      },
      {
        type: `paragraph`,
        text: `Our approach is rooted in the belief that healthcare should not be a reactive response to illness, but a proactive, personalized, and empowering journey. We are leveraging the power of technology to transform this belief into a reality, with our Clinical Ambient Intelligence Engine and Patient Lifecycle Management system at the core of this transformation.`,
      },
      {
        type: `paragraph`,
        text: `For individuals, we are a trusted partner in their health journey. We provide actionable insights and preventive measures, empowering them to predict a healthier future and take control of their health. We are taking the burden of health management off their shoulders, allowing them to focus on living their lives to the fullest.`,
      },
      {
        type: `paragraph`,
        text: `For healthcare providers, we are a catalyst for efficiency and effectiveness. Our solutions enhance management, reduce clinical burdens, and improve decision-making. This leads to better patient outcomes, improved efficiency, and a significant reduction in healthcare costs.`,
      },
      {
        type: `paragraph`,
        text: `For insurers, we are a game-changer. We eliminate guesswork and provide real-time insights, enabling them to create personalized plans, reduce fraud, and improve issuance rates. We are transforming insurance from a gamble to a calculated, evidence-based decision.`,
      },
      {
        type: `paragraph`,
        text: `At Formis, we are not just building bridges; we are transforming the healthcare landscape. We are making healthcare more accessible, efficient, and personalized, ensuring that everyone receives the right care at the right time. We are not just adding another product to the healthcare landscape; we are reshaping it, building bridges that connect, empower, and transform.`,
      },
      {
        type: `paragraph`,
        text: `Based on a study published in the National Library of Medicine, the lack of interoperability in healthcare systems can lead to inefficiencies and confusion. In India, the healthcare system is fragmented, with different entities like doctors, hospitals, pharmacies, and insurance companies operating in isolation. This lack of connection can lead to delays and confusion, making healthcare a complex maze for patients to navigate.`,
      },
      {
        type: `styled paragraph`,
        text: `For instance, a study published in the Journal of Family Medicine and Primary Care found that 45% of patient information is lost during transitions of care from one healthcare provider to another in India. This lack of information continuity can lead to poor patient outcomes, increased healthcare costs, and decreased patient satisfaction.`,
      },
      {
        type: `heading`,
        text: `Conclusion`,
      },
      {
        type: `paragraph`,
        text: `Disconnected experiences in healthcare are more than an inconvenience; they are a critical gap that affects the lives, well-being, and finances of individuals, healthcare providers, and insurers. The time has come to move beyond traditional silos and fragmented approaches.`,
      },
      {
        type: `paragraph`,
        text: `Formis Health Technologies aka Hey! Formis is building bridges to bring this transformation while redefining healthcare delivery with a focus on continuity, efficiency, and patient-centric care. We are not here to add to the noise; we are here to reshape healthcare, making it accessible, accountable, and affordable for all.`,
      },
      {
        type: `styled paragraph`,
        text: `We are Formis and we are reimagining healthcare delivery.`,
      },
    ],
  },

  // Blog 10
  {
    id: "healthcare-is-personal",
    img: img10,
    title:
      "Healthcare is Personal: How Technology is Empowering Individuals to Take Control of Their Health",
    desc: "The Power of Technology in Healthcare",
    content: [
      {
        type: `heading`,
        text: `The Power of Technology in Healthcare`
      },
      {
        type: `paragraph`,
        text: `Technology is giving us the tools to revolutionize healthcare. We can now collect and analyze vast amounts of data about our health, including our genetics, our lifestyle habits, and our medical history. This data can be used to create personalized care plans that are tailored to our individual needs.`
      },
      {
        type: `quote`,
        text: `“Technology is the future of healthcare. It’s the way we’re going to be able to deliver personalized care to patients.” — Eric Topol, MD`
      },
      {
        type: `heading`,
        text: `Personalized Healthcare`
      },
      {
        type: `paragraph`,
        text: `With personalized healthcare, patients are no longer treated as numbers. They’re treated as individuals with unique health profiles. This means that treatments can be tailored to each patient’s specific needs, which can lead to better outcomes.`
      },
      {
        type: `quote`,
        text: `“Personalized healthcare is the future of medicine. It’s the way we’re going to be able to prevent diseases and treat them more effectively.” — Francis Collins, MD, PhD`
      },
      {
        type: `heading`,
        text: `Bridging the Gap`
      },
      {
        type: `paragraph`,
        text: `Technology is bridging the gap between personalized healthcare and traditional healthcare. By collecting and analyzing vast amounts of data, technology can help us to identify the unique needs of each patient. This data can then be used to create personalized care plans that are tailored to each patient’s individual needs.`
      },
      {
        type: `quote`,
        text: `“Technology is the key to making personalized healthcare a reality. It’s the way we’re going to be able to collect and analyze the data we need to create personalized care plans for patients.” — Michael Chernew, PhD`
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:2000/format:webp/1*08lFNvjG_mu8KlN8ILtU2g.png`,
        alt: `Empowering Individuals`
      },
      {
        type: `subheading`,
        text: `Technology is empowering patients to take control of their own health. With access to their health records online, patients can track their vital signs and make informed decisions about their care. They can also connect with healthcare providers from anywhere in the world, which gives them more control over their care.`,
      },
      {
        type: `quote`,
        text: `“Patients are becoming more and more empowered to take control of their own health. Technology is giving them the tools they need to do this.” — Barbara K. Rimer, PhD`,
      },
      {
        type: `heading`,
        text: `A World Transformed`,
      },
      {
        type: `paragraph`,
        text: `The impact of technology and personalized healthcare is profound. It’s transforming the way we think about healthcare, the way we deliver healthcare, and the way we experience healthcare.`,
      },
      {
        type: `quote`,
        text: `“Technology is changing the face of healthcare. It’s making healthcare more personal, more preventive, and more empowering for patients.” — Reed Tuckson, MD`,
      },
      {
        type: `heading`,
        text: `The Synergy of Healthcare and Technology`,
      },
      {
        type: `paragraph`,
        text: `Healthcare and technology are a natural fit. They’re both designed to improve our lives. And when they’re combined, they can create powerful solutions that can transform the way we live and the way we receive care.`,
      },
      {
        type: `italic paragraph`,
        text: `“Healthcare and technology are converging to create a new era of care. It’s an era where patients are empowered to take control of their own health, and where healthcare providers have the tools they need to deliver personalized care.” — Michael Osterholm, PhD`,
      },
      {
        type: `heading`,
        text: `Introducing Hey Formis`,
      },
      {
        type: `paragraph`,
        text: `Hey Formis is an innovative solution that’s revolutionizing healthcare. It’s a platform that uses artificial intelligence (AI) to provide personalized healthcare advice and support.`,
      },
      {
        type: `italic paragraph`,
        text: `“Hey Formis by Formis Health Technologies Private Limited is a powerful platform that can help individuals take control of their health while being continuously and non-intrusively monitored by the Formis Care Team. Not only does Hey Formis empower individuals, but it also optimizes the institute’s resources and decision support systems. We are Formis. We are reimagining healthcare delivery.”`,
      },
      {
        type: `image`,
        src: `https://miro.medium.com/v2/resize:fit:2000/format:webp/1*6wRwUy93eiIDmV9NUrLfjw.png`,
        alt: `We are Formis, We are reimagining healthcare delivery.`
      },
      {
        type: `heading`,
        text: `Conclusion`,
      },
      {
        type: `subheading`,
        text: `The convergence of healthcare and technology holds immense promise in reshaping the future of healthcare. Hey Formis, the innovative solution by Formis Health Technologies, exemplifies the power of personalized care driven by technology. By combining the human touch with the efficiency and capabilities of digital tools, Hey Formis empowers individuals to take control of their health journey, while healthcare providers and institutions embrace a new era of patient-centric care. Together, we can create a world where healthcare is truly personal, technology is harnessed for its full potential, and lives are transformed for the better.`,
      },
    ],
  },
];
