import Expandable from "./Expandable";
import avi from "../../assets/about/avinandan.png";
import nikhil from "../../assets/about/nikhil.png";
import sarthak from "../../assets/about/sarthak.png";

const ProductFAQ = ({ productData }) => {
  const questionsAndAnswers =
    productData.questions !== "NA" &&
    productData.questions.split("|").map((qa) => {
      const [question, answer] = qa.split(":::");
      return { question: question.trim(), answer: answer.trim() };
    });

  return (
    <div className="lg:flex mt-14 bg-formisGrey/10 text-formisPeach rounded-[10px]">
      <div className="p-10 lg:w-[55%]">
        <h3 className="title-lg font-bold leading-none mb-4">FAQs</h3>
        {questionsAndAnswers.map((faq, index) => (
          <Expandable
            key={index}
            title={faq.question}
            faq={true}
            isFirst={index === 0}
          >
            <p className="font-[Hind]">{faq.answer}</p>
          </Expandable>
        ))}
      </div>

      <div className="p-10 lg:w-[45%] bg-formisGrey/10 rounded-r-[10px]">
        <h4 className="text-2xl lg:text-3xl leading-none font-bold mb-[.625rem]">
          Didn't find your answer?
        </h4>
        <p className="mb-6">Don't hesitate to contact us</p>
        <div className="flex mb-6">
          <div
            style={{ backgroundImage: `url(${avi})` }}
            className="bg-cover size-11 rounded-full "
          ></div>
          <div
            style={{ backgroundImage: `url(${nikhil})` }}
            className="bg-cover size-11 rounded-full relative right-3"
          ></div>
          <div
            style={{ backgroundImage: `url(${sarthak})` }}
            className="bg-cover size-11 rounded-full relative right-6"
          ></div>
        </div>

        <div className="flex gap-2 md:gap-5 mt-10">
          <a
            href="tel:+91-9911266699"
            className="flex items-center justify-center border-2 bg-formisPeach text-formisBlack15 border-formisPeach button-font px-2 md:px-6 py-4 w-[60%] rounded-full hover:bg-transparent hover:border-formisPeach hover:text-formisPeach transition-colors duration-300"
          >
            Contact Us
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
            className="flex items-center justify-center border-2 border-formisPeach button-font px-2 md:px-6 py-4 w-[60%] rounded-full hover:bg-formisPeach hover:border-formisPeach hover:text-formisBlack15 transition-colors duration-300 cursor-pointer"
          >
            Buy It Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductFAQ;
