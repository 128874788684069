import { useEffect, useState } from "react";
import logo from "../assets/formis-foressentials-logo.png";
import { Link } from "react-router-dom";
import { useGeolocation } from "../hooks/useGeolocation";

const HeaderNav = ({ isLoaded }) => {
  const { locationInfo } = useGeolocation();
  const [eta, setEta] = useState();

  const fetchETA = async () => {
    try {
      const response = await fetch(
        "https://kitsa.co.in/api/v1/eta?" +
          `latitude=${
            (locationInfo && locationInfo.latitude) || 28.48218399
          }&longitude=${
            (locationInfo && locationInfo.longitude) || 77.09330629
          }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setEta(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchETA();
  }, [locationInfo]);

  return (
    <header
      className={`${
        isLoaded ? "translate-y-0" : "-translate-y-full"
      } absolute top-0 left-0 z-50 w-full px-5 lg:px-9 xl:px-14 py-6 md:py-8 bg-transparent text-formisPeach
       transition-all duration-500`}
    >
      <div className={`grid grid-cols-2 items-center w-full px-2 lg:px-0`}>
        <div className="flex items-center gap-5">
          <Link to="/">
            <img
              src={logo}
              alt="FORMIS"
              className="max-w-[170px] md:max-w-[250px]"
            />
          </Link>
        </div>

        {/* Icons */}
        <div className="flex items-center justify-end gap-4 lg:gap-7 w-fit place-self-end">
          <div className="flex flex-col md:flex-row items-end md:items-center gap-2 justify-end leading-none">
            <p className="md:text-lg lg:text-2xl">Delivery in</p>
            <p className="font-bold md:text-lg lg:text-2xl">
              {eta && eta.eta_minutes} minutes
            </p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderNav;
