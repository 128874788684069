import { useState } from "react";

export const useGeolocation = () => {
  const [locationInfo, setLocationInfo] = useState();
  const [locationError, setLocationError] = useState();

  const { geolocation } = navigator;

  const success = (res) => {
    // console.log(res);
    setLocationInfo(res.coords);
  };

  const error = (res) => {
    // console.log(res);
    setLocationError(res.message);
  };

  if (!locationError && !locationInfo) {
    geolocation.getCurrentPosition(success, error);
  }

  return { locationError, locationInfo };
};
