// import React, { useState, useEffect } from "react";

// const OrderCounter = () => {
//   // Base counter settings
//   const initialCount = 4000; // Starting number
//   const incrementRate = 2; // Increment value
//   const incrementIntervalMinutes = 15; // Increment interval in minutes

//   // Specific start date and time in IST
//   const getStartTime = () => {
//     const startTime = new Date(2024, 10, 28, 0, 0, 0); // 28th November 2024, 12:00 AM (local time)
//     startTime.setTime(startTime.getTime() + 330 * 60 * 1000); // Convert to IST (UTC + 5:30)
//     return startTime;
//   };

//   // State to hold the current counter value
//   const [counter, setCounter] = useState(initialCount);

//   // Function to calculate the current count
//   const calculateCurrentCount = () => {
//     const now = new Date(); // Current time
//     const elapsedTime = now - getStartTime(); // Time difference in milliseconds
//     if (elapsedTime < 0) return initialCount; // If it's before the start time, use the initial count
//     const intervalsPassed = Math.floor(
//       elapsedTime / (incrementIntervalMinutes * 60 * 1000)
//     ); // Calculate 15-min intervals
//     return initialCount + intervalsPassed * incrementRate; // Updated counter value
//   };

//   useEffect(() => {
//     // Set the initial counter value
//     setCounter(calculateCurrentCount());

//     // Update the counter every second
//     const interval = setInterval(() => {
//       setCounter(calculateCurrentCount());
//     }, 1000);

//     // Cleanup interval on component unmount
//     return () => clearInterval(interval);
//   }, []); // Empty dependency array ensures this effect runs once

//   // Function to pad the counter value with leading zeros
//   const padNumber = (num, length) => num.toString().padStart(length, "0");

//   return (
//     <div id="orderCounter">
//       {padNumber(counter, 6)}
//     </div>
//   );
// };

// export default OrderCounter;


import React, { useState, useEffect } from "react";

const OrderCounter = () => {
  // Base counter settings
  const initialCount = 4000; // Starting number
  const incrementRate = 2; // Increment value
  const incrementIntervalMinutes = 15; // Increment interval in minutes

  // Specific start date and time in IST (using UTC to ensure consistency)
  const getStartTime = () => {
    // Create UTC time for 28th November 2024, 12:00 AM IST
    const startTime = new Date(Date.UTC(2024, 10, 27, 18, 30, 0)); // 27th Nov 2024, 6:30 PM UTC = 28th Nov 2024, 12:00 AM IST
    return startTime;
  };

  // State to hold the current counter value
  const [counter, setCounter] = useState(initialCount);

  // Function to calculate the current count
  const calculateCurrentCount = () => {
    const now = new Date(); // Current time (local or server time)
    const elapsedTime = now - getStartTime(); // Time difference in milliseconds
    if (elapsedTime < 0) return initialCount; // If it's before the start time, use the initial count
    const intervalsPassed = Math.floor(
      elapsedTime / (incrementIntervalMinutes * 60 * 1000)
    ); // Calculate 15-min intervals
    return initialCount + intervalsPassed * incrementRate; // Updated counter value
  };

  useEffect(() => {
    // Set the initial counter value
    setCounter(calculateCurrentCount());

    // Update the counter every second
    const interval = setInterval(() => {
      setCounter(calculateCurrentCount());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this effect runs once

  // Function to pad the counter value with leading zeros
  const padNumber = (num, length) => num.toString().padStart(length, "0");

  return (
    <div id="orderCounter">
      {padNumber(counter, 6)}
    </div>
  );
};

export default OrderCounter;
