import { useEffect, useState } from "react";
import Hero from "../components/Main Page Components/Hero/Hero";
import Features from "../components/Main Page Components/Features/Features";
import Highlights from "../components/Main Page Components/Highlights/Highlights";
import Footer from "../components/Footer/Footer";
import LoaderAnimation from "../components/LoaderAnimation";
import FAQ from "../components/Main Page Components/FAQ/FAQ";
import Carousel from "../components/Main Page Components/Carousel/Carousel";
import Products from "../components/Main Page Components/Products";
import HeaderNav from "../components/HeaderNav";
import DeliveriesCards from "../components/Main Page Components/DeliveriesCards"

const Main = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNavbarLoaded, setIsNavbarLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      const headerTimer = setTimeout(() => {
        setIsNavbarLoaded(true);
      }, 200);
      return () => clearTimeout(headerTimer);
    }
  }, [isLoaded]);

  return (
    <div>
      <LoaderAnimation isLoaded={isLoaded} />
      <HeaderNav isLoaded={isLoaded} />
      <div className={`relative overflow-hidden`}>
        {isLoaded && (
          <div>
            <Hero isLoaded={isNavbarLoaded} />
            {/* <DeliveriesCards /> */}
            <Products />
            <Features />
            <Carousel />
            <Highlights />
            <FAQ />
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
