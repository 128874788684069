// import AwardsMarquee from "../../AwardsMarquee";
// import heromain from "../../../assets/bg-hero-main.png";
// import { Link } from "react-router-dom";
// import OrderCounter from "../OrderCounter";

// const Hero = ({ isLoaded }) => {
//   return (
//     <header
//       className={`transition-opacity duration-1000 ${
//         isLoaded ? "opacity-100" : "opacity-0"
//       }`}
//       id="hero"
//     >
//       <section
//         className="bg-cover bg-no-repeat h-[100svh] md:h-[100vh] relative z-40 px-[40px] pt-[100px] lg:pt-[90px] 3xl:pt-[150px] pb-8 lg:pb-16 flex flex-col justify-between"
//         style={{ backgroundImage: `url(${heromain})` }}
//       >
//         <div className="absolute top-0 left-0 h-full w-full bg-formisBlack15/50"></div>

//         <section className="relative z-10">
//           <div className="flex flex-col justify-center">
//             <div
//               className="max-w-[920px] mx-auto text-formisPeach text-center
//                             mobile-landscape:text-[20px] mobile-landscape:mt-0"
//             >
//               <p className="text-lg leading-none mb-6">
//                 Making Smiles{" "}
//                 <span className="text-formisBlack15 text-[12px] md:text-[14px] border border-formisPeach bg-formisPeach rounded-full px-2 font-bold inline-block h-fit py-1">
//                   <OrderCounter />
//                 </span>{" "}
//                 <br className="md:hidden" />
//                 Delivered and Counting{" "}
//               </p>
//               <h1 className="header-text font-[500] leading-[2.35rem] md:leading-[3.5rem] xl:leading-[5rem] mobile-landscape:leading-[30px]">
//                 Get Your Health Essentials{" "}
//                 <br className="hidden lg:block mobile-landscape:block" />
//                 Delivered in Minutes!
//               </h1>
//             </div>

//             <div
//               className="font-light text-formisPeach max-w-[850px] text-center mx-auto
//                             mt-5 mobile-landscape:mt-2"
//             >
//               <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[30px] opacity-60 font-[500] tracking-wide">
//                 From daily health essentials to life-enhancing products,{" "}
//                 <span className="font-bold">FORMIS® forEssentials</span>{" "}
//                 delivers it all in just{" "}
//                 <span className="font-bold">15 minutes</span>.{" "}
//                 <span className="font-bold">Zero</span> delivery fees,{" "}
//                 <span className="font-bold">Zero</span> hidden charges,{" "}
//                 <span className="font-bold">Zero</span> minimum cart value—just
//                 fast, simple, reliable service at your fingertips. Because when
//                 it comes to your health, you’ve got FORMIS.
//               </p>
//             </div>

//             <div className="flex flex-col md:flex-row gap-2 md:gap-5 items-center justify-center font-[500] mt-10 mobile-landscape:mt-5">
//               <a
//                 target="_blank"
//                 rel="noreferrer"
//                 href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
//                 className="text-formisBlack15 text-[12px] md:text-[14px] border border-formisPeach bg-formisPeach h-[47px] rounded-full px-4
//                           hover:bg-transparent hover:text-formisPeach transition-colors cursor-pointer flex items-center justify-center
//                           mobile-landscape:h-[38px]"
//               >
//                 Order Now On WhatsApp
//               </a>
//               <Link to="/products">
//                 <button
//                   className="hidden lg:block text-formisPeach text-[14px] border border-formisPeach h-[47px] rounded-full px-6
//                                 hover:bg-formisPeach hover:text-formisBlack15 transition"
//                 >
//                   View Catalogue
//                 </button>
//               </Link>
//             </div>
//           </div>
//         </section>

//         <div
//           className="px-[20px] md:mx-[40px] lg:mx-[60px] 2xl:mx-[90px]
//                         mobile-landscape:h-[30%]"
//         >
//           <AwardsMarquee />
//         </div>
//       </section>
//     </header>
//   );
// };

// export default Hero;

import AwardsMarquee from "../../AwardsMarquee";
import heromain from "../../../assets/bg-hero-main.png";
import { Link } from "react-router-dom";
import OrderCounter from "../OrderCounter";

const Hero = ({ isLoaded }) => {
  return (
    <header
      className={`transition-opacity duration-1000 ${
        isLoaded ? "opacity-100" : "opacity-0"
      }`}
      id="hero"
    >
      <section
        className="bg-cover bg-no-repeat relative z-40 px-[40px] pt-[100px] lg:pt-[90px] 3xl:pt-[150px] pb-8 lg:pb-16 
                  flex flex-col gap-6 md:gap-14 lg:gap-[6.25rem] xl:gap-16 2xl:gap-36 3xl:gap-52 justify-between"
        style={{ backgroundImage: `url(${heromain})` }}
      >
        <div className="absolute top-0 left-0 h-full w-full bg-formisBlack15/50"></div>

        <section className="relative z-10">
          <div className="flex flex-col justify-center">
            <div
              className="max-w-[920px] mx-auto text-formisPeach text-center
                            mobile-landscape:text-[20px] mobile-landscape:mt-0"
            >
              <p className="text-lg leading-none mb-4">
                Making Smiles{" "}
                <span className="text-formisBlack15 text-[12px] md:text-[14px] border border-formisPeach bg-formisPeach rounded-full px-2 font-bold inline-block h-fit py-1">
                  <OrderCounter />
                </span>{" "}
                <br className="md:hidden" />
                Delivered and Counting{" "}
              </p>
              <h1 className="header-text font-[500] leading-[2.35rem] md:leading-[3.5rem] xl:leading-[5rem] mobile-landscape:leading-[30px]">
                Get Your Health Essentials{" "}
                <br className="hidden lg:block mobile-landscape:block" />
                Delivered in Minutes!
              </h1>
            </div>

            <div
              className="font-light text-formisPeach max-w-[850px] text-center mx-auto 
                            mt-5 mobile-landscape:mt-2"
            >
              <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] leading-[20px] lg:leading-[30px] opacity-60 font-[500] tracking-wide">
                “Why does this always happen at midnight” pill, FORMIS®
                forEssentials makes <b>medicine delivery</b> a breeze. Need to{" "}
                <b>buy medicine</b>? We’ve got you covered—delivered in just 15
                minutes. Oh, and here’s the cherry on top:{" "}
                <b>get discounts and benefits of up to 30%</b> while you’re at
                it. Zero delivery fees. Zero hidden charges. Zero minimum cart
                drama. Just fast, reliable service that’s all about keeping your
                health stress-free.{" "}
                <b>Order your medicine online by simply using WhatsApp now!</b>
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-2 md:gap-5 items-center justify-center font-[500] mt-6 mobile-landscape:mt-5">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
                className="text-formisBlack15 text-[12px] md:text-[14px] border border-formisPeach bg-formisPeach h-[47px] rounded-full px-4 
                          hover:bg-transparent hover:text-formisPeach transition-colors cursor-pointer flex items-center justify-center
                          mobile-landscape:h-[38px]"
              >
                Order Now On WhatsApp
              </a>
              <Link to="/products">
                <button
                  className="hidden lg:block text-formisPeach text-[14px] border border-formisPeach h-[47px] rounded-full px-6 
                                hover:bg-formisPeach hover:text-formisBlack15 transition"
                >
                  View Catalogue
                </button>
              </Link>
            </div>
          </div>
        </section>

        <div
          className="px-[20px] md:mx-[40px] lg:mx-[60px] 2xl:mx-[90px]
                        mobile-landscape:h-[30%]"
        >
          <AwardsMarquee />
        </div>
      </section>
    </header>
  );
};

export default Hero;
