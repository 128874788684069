import JoinContact from "../components/About Page Components/JoinContact";
import Team from "../components/About Page Components/Team";
import Values from "../components/About Page Components/Values";
import Footer from "../components/Footer/Footer";
import HeaderNav from "../components/HeaderNav";

const About = () => {
  return (
    <main>
      <HeaderNav isLoaded={true} />

      <div className="pt-[160px]">
        <section className="text-formisPeach px-5 lg:px-9 xl:px-14 pb-[60px]">
          <h6 className="text-[12px] font-medium tracking-widest mb-5">
            ABOUT
          </h6>
          <h1 className="text-[34px] md2:text-[58px] xl:text-[72px] 3xl:text-[92px] font-medium mb-5">
            MEET FORMIS
          </h1>
        </section>
        <Team />
        <Values />
        <JoinContact />
      </div>

      <Footer />
    </main>
  );
};

export default About;
