import Accordian from "./Accordian";

const Values = () => {
  return (
    <section className="lg:flex lg:gap-20 bg-formisBlack15 px-5 lg:px-9 xl:px-14 py-[80px] lg:py-[100px]">
      <div className="mb-5 text-formisPeach">
        <h5 className="text-[22px] md:text-[24px] lg:text-[34px] text-center lg:text-left mb-3">
          Healthcare, made simple.
        </h5>
        <p className="font-[Hind] opacity-70 lg:text-[18px]">
          Transforming healthcare by integrating innovative technology with a
          human-centered approach, enabling proactive, personalized, and
          continuous care for all.
        </p>
      </div>

      <div>
        <Accordian color="black" title="Preventive and Continuous Care">
          <p className="font-[Hind] text-[16px] md2:text-[18px] 2xl:text-[18px] 3xl:text-[20px] opacity-80">
            Formis emphasizes proactive health management, focusing on early
            detection and continuous care to improve health outcomes.
          </p>
        </Accordian>
        <Accordian color="black" title="Personalization and Accessibility">
          <p className="font-[Hind] text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            It strives to provide personalized health management, ensuring that
            healthcare services are accessible, understandable, and actionable
            for individuals.
          </p>
        </Accordian>
        <Accordian color="black" title="Innovation and Technology">
          <p className="font-[Hind] text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            Formis leverages cutting-edge technologies like AI, machine
            learning, and federated learning to deliver predictive healthcare
            insights while maintaining data privacy.
          </p>
        </Accordian>
        <Accordian color="black" title="Collaboration and Interoperability">
          <p className="font-[Hind] text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            The platform integrates healthcare services and data across various
            stakeholders, including hospitals, insurers, and patients, ensuring
            seamless care coordination and improving operational efficiency.
          </p>
        </Accordian>
        <Accordian color="black" title="Community Engagement">
          <p className="font-[Hind] text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            Formis fosters a culture of health and wellness by engaging
            communities in healthcare delivery, promoting a lifestyle program
            culture that encourages well-being.
          </p>
        </Accordian>
      </div>
    </section>
  );
};

export default Values;
