import { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import HeaderNav from "../components/HeaderNav";
import { Link } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";
import imageNA from "../assets/Image-NA.png";
import bgMain from "../assets/bg-hero-main.png";
import useDebounce from "../hooks/useDebounce";

const AllProducts = () => {
  const [productsData, setProductsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const debouncedSearchInput = useDebounce(searchInput, 1000);

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines-es?page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductsData(data);
      }
    } catch {}
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 300);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 300);
  };

  const fetchSearchResults = async (searchQuery) => {
    if (!searchQuery) {
      setSearchResults([]);
      setLoading(false);
      return;
    }
    setLoading(false);
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicine-search?search=${searchQuery}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSearchResults(data);
        console.log("Search: ", data);
      } else {
        setSearchResults([]);
      }
    } catch {
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    if (!searchInput) {
      setSearchResults([]);
    }
  }, [searchInput]);

  useEffect(() => {
    if (debouncedSearchInput) {
      fetchSearchResults(debouncedSearchInput);
    }
  }, [debouncedSearchInput]);

  // INPUT PLACEHOLDER ANIMATION
  const [currentText, setCurrentText] = useState("");
  const [visible, setVisible] = useState(true);
  const list = ["Paracetamol", "Dolo", "Crocin"];
  let i = 0;

  const textAnimation = () => {
    setCurrentText(list[i]);
    setVisible(true);
    setTimeout(() => setVisible(false), 1500);
    i = (i + 1) % list.length;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      textAnimation();
    }, 1700);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <HeaderNav isLoaded={true} />

      <div
        style={{
          backgroundImage: `url(${bgMain})`,
        }}
        className="relative h-[30vh] md:h-[45vh] xl:h-[60vh] bg-no-repeat bg-cover bg-[50%] bg-formisBlack"
      >
        <div className="h-full w-full absolute top-0 left-0 bg-formisBlack15/30" />

        <div className="h-full w-full absolute flex items-center justify-center text-formisPeach">
          <div className="flex gap-2 lg:gap-5 items-center relative z-10">
            <Link to="/">
              <svg
                className="w-8 lg:w-10"
                viewBox="0 0 20 21"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.333 14.667v-3.713c0-1.355 0-2.033-.165-2.66a5 5 0 0 0-.818-1.702c-.387-.521-.916-.945-1.974-1.791l-.378-.303h0c-1.784-1.427-2.676-2.14-3.665-2.414a5 5 0 0 0-2.666 0c-.99.274-1.881.987-3.665 2.414h0l-.378.303c-1.058.846-1.587 1.27-1.974 1.79a5 5 0 0 0-.818 1.703c-.165.627-.165 1.305-.165 2.66v3.713a4.167 4.167 0 0 0 4.166 4.166c.92 0 1.667-.746 1.667-1.666v-3.334a2.5 2.5 0 0 1 5 0v3.334c0 .92.746 1.666 1.667 1.666a4.167 4.167 0 0 0 4.166-4.166Z"
                ></path>
              </svg>
            </Link>
            <div className="relative w-[15rem] md:w-[25rem] xl:w-[60rem] px-2 md:px-4 flex mx-auto items-center text-formisBlack15 bg-formisWhitef0/50 rounded-[10px] backdrop-blur-md">
              <svg
                className="w-7 cursor-pointer"
                viewBox="0 0 20 20"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.4007 17.4998L15.3707 14.4698M15.3707 14.4698C16.7279 13.1126 17.5674 11.2376 17.5674 9.1665C17.5674 5.02437 14.2095 1.6665 10.0674 1.6665C5.92525 1.6665 2.56738 5.02437 2.56738 9.1665C2.56738 13.3086 5.92525 16.6665 10.0674 16.6665C12.1385 16.6665 14.0135 15.827 15.3707 14.4698Z"
                ></path>
              </svg>
              <input
                // placeholder={`Type Here To Search`}
                id="search-input"
                className="bg-transparent font-[500] md2:text-2xl pl-2 md:pl-5 py-2 w-full placeholder:text-formisBlack15 outline-none"
                onChange={handleInputChange}
              />
              {searchInput === "" && (
                <div className="md2:text-2xl placeholder absolute top-2 left-10 md:left-16 flex gap-3 pointer-events-none">
                  <div className="box">
                    <p>Search For</p>
                  </div>
                  <div className="slider_box relative">
                    <div
                      className={`slider absolute left-[-5px] ${
                        visible
                          ? "opacity-100 translate-y-0"
                          : "opacity-0 -translate-y-full"
                      } transition-all`}
                    >
                      '{currentText}'
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-formisBlack15 absolute bottom-0 left-0 w-full h-10 rounded-t-[15px]"></div>
      </div>

      {searchInput ? (
        <div className="bg-formisBlack15 px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20">
          {!loading ? (
            searchResults.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 product-card-gap">
                {searchResults.map((product, index) => (
                  <ProductCard
                    key={index}
                    image={product.image_url}
                    name={product.name}
                    mrp={product.mrp}
                    desc={product.packaging}
                    slug={product.slug}
                    salt={product.salt_composition}
                    ingredients={product.ingredients}
                    manufacturer={product.manufacturer}
                  />
                ))}
              </div>
            ) : (
              <div>not found</div>
            )
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 product-card-gap">
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="bg-formisBlack15 text-formisPeach px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 mb-10">
            <h2 className="text-[48px] md:text-[60px] leading-none font-bold mb-5">
              Your Everyday Health, Delivered Seamlessly
            </h2>
            <p className="mb-5">
              From trusted remedies to daily wellness must-haves, we’ve curated
              a selection that puts your health first—without the hassle.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
              className="text-formisBlack15 w-fit text-[12px] md:text-[14px] border border-formisPeach bg-formisPeach h-[47px] rounded-full px-4 
                          hover:bg-transparent hover:text-formisPeach transition-colors cursor-pointer flex items-center justify-center
                          mobile-landscape:h-[38px]"
            >
              Order Now On WhatsApp
            </a>
          </div>
          <div className="bg-formisBlack15 px-[1rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20">
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 product-card-gap">
              {productsData &&
                productsData.results.map((product, index) => (
                  <ProductCard
                    key={index}
                    image={product.image_url}
                    name={product.name}
                    mrp={product.mrp}
                    desc={product.packaging}
                    slug={product.slug}
                    salt={product.salt_composition}
                    ingredients={product.ingredients}
                    manufacturer={product.manufacturer}
                  />
                ))}
            </div>
          </div>

          <div className="flex gap-5 items-center justify-center">
            {currentPage !== 1 && (
              <button
                className="bg-formisPeach py-3 px-5 rounded-full flex items-center justify-center gap-2"
                onClick={handlePreviousClick}
              >
                <MdNavigateNext className="rotate-180 text-xl" />
                Previous
              </button>
            )}
            <button
              className="bg-formisPeach py-3 px-5 rounded-full flex items-center justify-center gap-2"
              onClick={handleNextClick}
            >
              Next <MdNavigateNext />
            </button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default AllProducts;

const ProductCard = ({
  name,
  desc,
  image,
  mrp,
  slug,
  salt,
  ingredients,
  manufacturer,
}) => {
  return (
    <div className="h-full flex flex-col justify-between rounded-[10px] border border-formisPeach">
      <div className="relative">
        <Link to={`/products/${slug}`}>
          <img
            src={
              image !== "NA" &&
              image !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? image
                : imageNA
            }
            alt={name}
            className="rounded-t-[10px]"
          />
        </Link>
        <div className="bg-formisBlack15 rounded-b-[10px] absolute -top-[1px] right-0">
          <p className="text-sm text-formisPeach p-2">Learn More</p>
        </div>

        <div className="flex flex-col justify-between text-formisPeach py-4 px-2 rounded-b-[10px]">
          <div>
            <span className="text-[12px] tracking-widest capitalize">
              {desc}
            </span>
            <p className="font-[600] lg:text-[19px] leading-tight my-2">
              {name}
            </p>
            <p className="text-sm font-[600] mb-2">MRP {mrp.toFixed(2)}/-</p>
            <p className="font-[Hind] text-xs md:text-sm font-[600] mb-2">
              Composition: {salt !== "NA" ? salt : ingredients}
            </p>
            <p className="font-[Hind] text-xs md:text-sm font-[600] mb-2">
              Manufacturer: {manufacturer}
            </p>
          </div>
        </div>
      </div>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
        className="flex items-center justify-center justify-self-end text-sm bg-formisPeach text-formisBlack15 px-2 py-1 rounded-b-[10px]"
      >
        Check Offers
      </a>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div>
      <div className="rounded-t-[10px] size-40 md:size-[11.25rem] md2:size-60 lg:size-64 xl:size-64 2xl:size-96 bg-formisGrey/50 mb-2" />
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
      <div className="h-2 w-40 md:w-[11.25rem] md2:w-60 lg:w-64 xl:w-64 2xl:w-96 rounded-full bg-formisGrey/50 mb-2"></div>
    </div>
  );
};
