import { useState } from "react";
import { FiPlus } from "react-icons/fi";

const Expandable = ({ title, children, id, faq, isFirst }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`scroll-smooth ${!isFirst && "border-t"} ${faq ? "border-formisPeach/20" : "border-formisBlack/20"} pb-6`} id={id}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`${faq ? "text-formisPeach" : "text-formisBlack"} pt-6  font-medium flex justify-between items-center w-full`}
      >
        <span className="font-[600] lg:text-[17px] xl:text-[18px] 3xl:text-[19px] text-left">{title}</span>

        <div className={`${isOpen ? "-rotate-45" : ""} text-lg font-light transition-all duration-300`}>
          <FiPlus />
        </div>
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className={`${isOpen ? "mt-4" : ""} transition-all ${faq ? "text-formisPeach" : "text-formisBlack"} overflow-hidden`}>
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Expandable;
