import ProductCard from "../components/Product Page Components/ProductCard";
import ProductFeatures from "../components/Product Page Components/ProductFeatures";
import ProductFAQ from "../components/Product Page Components/ProductFAQ";
import HeaderNav from "../components/HeaderNav";
import Footer from "../components/Footer/Footer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductReferences from "../components/Product Page Components/ProductReferences";

const Product = () => {
  const { productId } = useParams();
  const [productData, setProductData] = useState();

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines/${productId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductData(data);
        // console.log("Single Product Data:", data);
      }
    } catch {}
  };

  useEffect(()=>{
    fetchProducts();
  }, [])

  return (
    <div>
      <HeaderNav isLoaded={true} />

      {productData && <div className="text-formisPeach bg-formisWhite px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20 pt-[100px] lg:pt-[160px]">
        <ProductCard productData={productData} />
        <ProductFeatures productData={productData} />
        {productData.questions!=="NA" && <ProductFAQ productData={productData} />}
        {/* <ProductReferences productData={productData} /> */}
      </div>}

      <Footer />
    </div>
  );
};

export default Product;
