import { useState } from "react";
import img1 from "../../../assets/img4.png";
import img2 from "../../../assets/img5.png";
import img3 from "../../../assets/img7.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
  const [image, setImage] = useState(img1);
  const images = [img1, img2, img3];

  useGSAP(() => {
    const sections = gsap.utils.toArray("#scrollbar > div");

    let mm = gsap.matchMedia();
    mm.add("(min-width: 1024px)", () => {
      sections.forEach((section, index) => {
        ScrollTrigger.create({
          trigger: section,
          start: "top 40%",
          end: "bottom 40%",
          onEnter: () => setImage(images[index]),
          onEnterBack: () => setImage(images[index]),
        });
      });

      ScrollTrigger.create({
        trigger: "#split-container",
        start: "top top",
        end: "bottom bottom",
        pin: "#image-section",
      });
    });

    gsap.utils.toArray(".fade-in").forEach((element) => {
      gsap.fromTo(
        element,
        { opacity: 0, translateY: "100px" },
        {
          opacity: 1,
          translateY: "0px",
          duration: 0.75,
          stagger: 0.3,
          ease: "power2.out",
          scrollTrigger: {
            trigger: element,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  return (
    <div id="how-it-works">
      <div
        className="bg-formisBlack15 text-formisPeach
                    px-5 lg:px-9 xl:px-14 pt-10 lg:pt-20 pb-[48px] lg:pb-0"
      >
        <div className="fade-in text-4xl md:text-6xl lg:text-[5.5rem] font-medium mb-[60px] lg:mb-0">
          <h1 className="leading-[2.5rem] md:leading-[4.5rem] lg:leading-[7rem]">
            HOW IT WORKS<span className="text-formisOrange">.</span>
          </h1>
        </div>

        <div id="split-container" className="flex lg:h-[300vh] lg:px-[70px]">
          {/* ScrollBar */}
          <section
            id="scrollbar"
            className="flex flex-col gap-[48px] lg:block lg:max-w-[50%]"
          >
            <div className="fade-in lg:h-[100vh] flex flex-col items-start justify-center">
              <div className="flex gap-2 items-center mb-5">
                <div className="size-3 rounded-full bg-formisOrange"></div>
                <span className="text-sm md:text-lg font-medium">01</span>
              </div>
              <div className="text-[20px] md:text-[34px] leading-[1.75rem] md:leading-[2.5rem] mb-[16px] md:mb-5">
                <h3>Order in 20 Seconds</h3>
              </div>
              <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] lg:max-w-[50%] mb-5 opacity-60">
                Forget clunky apps—use{" "}
                <span className="font-bold">WhatsApp</span> to order your{" "}
                <span className="font-bold">health essentials</span> in seconds.
                Whether it’s a wellness kit or something for your lifestyle,{" "}
                <span className="font-bold">FORMIS</span> delivers it
                effortlessly.
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
                className="mb-[32px] lg:mb-0 bg-formisPeach h-[47px] text-[12px] md:text-[14px] font-[500] px-4 border border-formisPeach
                          flex items-center justify-center rounded-full text-formisBlack15 cursor-pointer
                          hover:bg-transparent hover:text-formisPeach transition-colors"
              >
                Order Now On WhatsApp
              </a>
              <img src={img1} alt="Order in 20 seconds" className="block lg:hidden" />
            </div>

            <div className="fade-in lg:h-[100vh] flex flex-col items-start justify-center">
              <div className="flex gap-2 items-center mb-5">
                <div className="size-3 rounded-full bg-formisOrange"></div>
                <span className="text-sm md:text-lg font-medium">02</span>
              </div>
              <div className="text-[20px] md:text-[34px] leading-[1.75rem] md:leading-[2.5rem] mb-[16px] md:mb-5">
                <h3>Delivered in 15 Minutes</h3>
              </div>
              <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] lg:max-w-[50%] mb-[32px] lg:mb-0 opacity-60">
                <span className="font-bold">
                  Experience seamless and swift care delivery within just 15
                  minutes.{" "}
                </span>
                Whatever you need, <span className="font-bold">FORMIS</span>{" "}
                delivers fast.
              </p>
              <img src={img2} alt="Delivery in 15 minutes" className="block lg:hidden" />
            </div>

            <div className="fade-in lg:h-[100vh] flex flex-col items-start justify-center">
              <div className="flex gap-2 items-center mb-5">
                <div className="size-3 rounded-full bg-formisOrange"></div>
                <span className="text-sm md:text-lg font-medium">03</span>
              </div>
              <div className="text-[20px] md:text-[34px] leading-[1.75rem] md:leading-[2.5rem] mb-[16px] md:mb-5">
                <h3>Zero Extra Costs</h3>
              </div>
              <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] lg:max-w-[50%] mb-[32px] lg:mb-0 opacity-60">
                No hidden fees. No delivery charges. Whether you’re grabbing{" "}
                <span className="font-bold">
                  lifestyle products or health aids, the cure is around the
                  corner,
                </span>{" "}
                and it won’t cost you extra.
              </p>
              <img src={img3} alt="No extra cost" className="block lg:hidden" />
            </div>
          </section>

          {/* SideBar Images */}
          <section
            className="hidden lg:flex place-self-start h-screen items-center max-w-[50%]"
            id="image-section"
          >
            <img
              src={image}
              className="rounded-lg h-auto w-full"
              alt="Feature"
              onLoad={() => ScrollTrigger.refresh()}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Features;
