import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import img1 from "../../../assets/carousel/1.png";
import img2 from "../../../assets/carousel/2.png";
import img3 from "../../../assets/carousel/3.png";
import img4 from "../../../assets/carousel/4.png";

const Carousel = () => {
  return (
    <div className="bg-formisBlack15 text-formisPeach px-5 lg:px-9 xl:px-14 py-20">
      <section className="fade-in text-4xl md:text-6xl lg:text-[5.5rem] font-medium mb-[80px] lg:mb-[140px]">
        <h1 className="leading-[2.5rem] md:leading-[4.5rem] lg:leading-[7rem]">
          The FORMIS Difference
          <span className="text-formisOrange">.</span> <br />
        </h1>
      </section>
      <Swiper
        spaceBetween={30}
        slidesPerView={3.75}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".next-btn",
          prevEl: ".prev-btn",
        }}
        modules={[Navigation]}
        breakpoints={{
          200: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          600: {
            slidesPerView: 2.5,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        }}
      >
        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img1}
                alt="Trusted By Thousands"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Trusted by Thousands Like You
                </h2>
                <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  Thousands are loving the FORMIS forEssentials way—fast,
                  affordable, and hassle-free. Ready to experience <b>healthcare
                  that actually works for you? Just say 'Hey, FORMIS' and get
                  started!</b>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img2}
                alt="Medication Refills"
                className="w-full aspect-auto  mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Managed Monthly Medication Refills
                </h2>
                <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  Monthly refills? Handled. No last-minute runs or oops-I-forgot
                  moments.{" "}
                  <b>
                    Just set it up, and FORMIS forEssentials will keep your
                    health essentials stocked like clockwork—zero stress, all
                    convenience.
                  </b>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img3}
                alt="On Demand Essentials and Medicines"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[270px] md:h-[300px] md7:h-[250px] 3xl:h-[230px] flex flex-col justify-between">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  On Demand Essentials and Medicines
                </h2>
                <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  Essentials and medicines, simplified. Order when you need them,
                  get them delivered fast. No waiting, no stress—just health
                  essentials at your fingertips.{" "}
                  <b>Because taking care of yourself should be this easy.</b>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="text-formisPeach">
            <div className="">
              <img
                src={img4}
                alt="No Hassle"
                className="w-full aspect-auto mb-[40px]"
              />
            </div>

            <div className="h-[320px] md:h-[370px] md2:h-[250px] 3xl:h-[300px] flex flex-col">
              <div className="">
                <h2 className="text-[16px] lg:text-[20px] mb-[20px] font-[600]">
                  Never on MRP, No Hassle
                </h2>
                <p className="font-[Hind] text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] text-formisPeach opacity-60">
                  <b>Why pay MRP when you can save up to 25%?</b>{" "}
                  <b>No hassle, no drama</b>—just your health essentials
                  delivered fast and easy. FORMIS forEssentials has your back,
                  so you can relax and let us do the heavy lifting!
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <section className="flex  md:flex-row gap-2 md:gap-6 mt-10 justify-end">
        <div className="flex justify-end gap-4">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send/?phone=919773910084&text=Hey%21+Formis&type=phone_number&app_absent=0"
            className="bg-transparent h-[47px] px-2 md:px-4 rounded-full text-formisPeach cursor-pointer border border-formisPeach
                      flex items-center justify-center text-[10px] md:text-[14px] font-[500]
                      hover:bg-formisPeach hover:border-formisPeach hover:text-formisBlack15 transition-colors"
          >
            Order Now On WhatsApp
          </a>
        </div>

        <div className="flex gap-2 md:gap-4 justify-end">
          <button className="bg-formisPeach text-formisBlack15 prev-btn font-light text-xs h-[47px] w-[47px] flex items-center justify-center rounded-full">
            <FaArrowLeft />
          </button>
          <button className="bg-formisPeach text-formisBlack15 next-btn font-light text-xs h-[47px] w-[47px] flex items-center justify-center rounded-full">
            <FaArrowRight />
          </button>
        </div>
      </section>
    </div>
  );
};

export default Carousel;
